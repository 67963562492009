.c-pagination{
    padding:1em 0;
}

.c-pages{
    margin:0;
    text-align: center;

    &--item{
        display:inline-block;
        padding:0 0.5em; 
        padding-right: 0.75em;
        position:relative;

        &:after{
            content:"";
            width:1px;
            height:10px;
            background:$darkGrey;
            position: absolute;
            right: 0;
            top:35%;
        }

        &:last-child{
            &:after{
                content:none;
            }
        }

        a{
            color:$darkBlue;
            font-weight:700;
        }
    }
}