.c-introduction{
    //background:$darkGrey;
    @extend .o-padding;
    &--heading{
        color:$gamma;
        padding-bottom:0.5em;
        margin-bottom:1em;
    }

    &--text{
        color:$gamma;
        font-size:rem(22);
    }

    a{
        color:$gamma;
        font-weight:700;
    }

    &.home{
        text-align: center;
    }
}