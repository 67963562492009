.c-nextprevious{
    @extend .o-padding;
    
    &--heading{
        font-size:rem(24);
    }

    a{
        color:$darkBlue;
        text-transform:uppercase;
        display: inline-block;
    }

    &--prev{
        background:url(../images/previous.svg) no-repeat left center;
        padding-left:40px;
    }

    &--next{
        background:url(../images/next.svg) no-repeat right center;
        padding-right:40px;
        text-align:right;
    }
}