/**
 * SETTINGS.GRID
 *
 * @description : Settings used in grid calculations
 *
 */

$bodyMax:        82rem;
$bodyMaxMedium:  74rem;
$bodyMaxSlim:    62rem;
$gridCols:       12;
$boxSizing:      border-box;
$includeIndent:  true;  // Set to true to create indent classes

// Default Gutters
$gutterValue:    26 !default;  // based on Piet & Diego's work 2.333% for 12 columns, 1.166% for 24 columns (12 * 2.333 = ~28 and 24 * 1.166 = ~28 too)
$gutter:         percentage($gutterValue / $gridCols / 100);
$negativeGutter: percentage($gutterValue / $gridCols / 100 * -1);
$noMargin:       0 percentage($gutterValue / $gridCols / 100 * -1);
