.c-form--blue{


    label{
        display: none;
    }

    .umbraco-forms-field{
        margin-bottom:1em;
    }


    input[type="text"],
    input[type="email"],
    textarea,
    select{
        display: block;
        background:#E3E7EA;
        border:none;
        padding:10px;
        width:100%;
        height:40px; // height is here to make sure the select is the same height as other input
        font-family: $bodyFont;
        font-size:rem(14);
    }

    input[type="submit"]{
        display:block;
        width:100%;
        background:$darkBlue;
        color:$white;
        border:none;
        padding:10px 5px;
    }
}