/**
 * ELEMENTS.TYPE
 *
 * @description : Basic typographical styles
 *
 */

// HEADINGS
h1, h2, h3, h4, h5, h6 {
    font-family: $headingFont;
    line-height:1.1;
    margin-bottom: 0.5em;
    font-weight:500;
}

h1 {
    font-size: rem(50);
}

h2 {
    font-size: rem(40);

    @media all and (max-width:$md){
        font-size:rem(32);
    }
}

h3 {
    font-size: rem(28);
    font-weight: 400;
    color:$darkBlue;
    margin:0.80em 0;
}

h4 {
    font-size: rem(22);
}

h5 {
    font-size: rem(20);
}

h6 {
    font-size: rem(21);
}

p, ul, ol {
    margin-bottom:1em;
}

ol {
 
    li{
        list-style-type:decimal;
    }
}

strong{
    font-weight:600;
}

a{
    color:$blue;
    text-decoration: none;

    &:hover{
        text-decoration:underline;
    }
}