.c-cards{
    @extend .o-padding;

    .c-btn{
        margin-top: 2em;
    }
}

.c-card{
    position:relative;
    margin-bottom:1em;
    overflow:hidden;
    border-radius: 25px;

    &:after{
        content:"";
        position:absolute;
        top:0;
        bottom:0;
        left:0;
        right: 0;
        background:rgba($black,0.1);
    }


    &--img{
        width:100%;
        transition:all 0.3s ease;
    }

    &--heading{
        position: absolute;
        bottom:1em;
        left:1em;
        color:$white;
        margin:0;
        font-size:rem(23);
        z-index: 20;
        i{
            font-size: 1.3rem;
            margin-left: 1.3em;
            @include transition-all(.2s);
        }
    }

    &:hover{
        &:after{
            background:rgba($darkBlue,0.3);
        }
        .c-card--img{
            transform: scale(1.05, 1.05);
        }
        i{
            margin-left: 1.5em;
        }
    }

    &.closed{
        height:0;
    }

    .expandable &{
        //display: none;
    }
    
}