.c-backtop{
    padding:2em 0;

    &--link{
        display:inline-block;
        background:$darkGrey;
        padding:0.55em 1.5em;
        border-radius: 5px;

        .far{
            color:$white;
            font-size:rem(30);
        }
    }
}