/**
 * ELEMENTS.BASE
 *
 * @description : Base element styles
 *
 */

html,
body {
    min-height: 100%;
    color: $textColor;
    font-family: $bodyFont;
    font-size: $base-font-size;
    line-height: $base-line-height;

    &.dev_type {
        @include baseline_grid();
    }
}
