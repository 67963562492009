.c-enquiry{
    @extend .o-padding;

    &--heading{
        font-size:rem(26);
        padding:1em 0;

        &.image{
            background:url(../images/pin-contact.png) no-repeat right center;
        }
    }

    form{
        textarea{
            min-height:150px;
            
        }
    }
}