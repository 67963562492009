.c-search{

    &--results{
        font-weight: 700;
        font-size:rem(18);
        color:$darkGrey;

        span{
            font-style:italic;
            color:$darkBlue;
        }

        
    }

    &--bar{
        border-bottom:1px solid $mediumGrey;
        padding-bottom:1em;

        p{
            margin:0;
        }
        
        &.bottom{
            border-top:1px solid $mediumGrey;
            padding-top:1em;
            margin-bottom:3em;
            border-bottom:none;
        }

        &--link{
            font-size:rem(18);
        }
    }

    &--again{
        background:$mediumGrey;
        width:75%;
        display:inline-block;

        input{
            float: left;
            background:transparent;
            border:none;
            width: calc(100% - 43px);
            padding: 10px 10px;
            display: inline-block;
            vertical-align: middle;
            color: $black;

            &:focus{
                outline: none;
            }            
        }

        button{
            background:$blue;
            border:none;
            display:inline-block;
            width:40px;
            height:40px;
            vertical-align: middle;
            cursor:pointer;

            .far{
                font-size:rem(20);
                color:$white;
            }

            &:hover{
                background:$darkBlue;
            }
        }
    }

    &--toggle{
        display:inline-block;
        cursor: pointer;
        padding:0.5em 0.8em;
        margin-right:1em;
        //width:30px;
        text-align:center;

        background-color: $beta;
        border-radius: 5px;
        color: $white;
        text-transform: uppercase;
        letter-spacing: 1px;

        .far{
            margin-left: 0.5em;
            color:$white;
            font-size:rem(18);
        }

        @media all and (max-width:$md){
            font-size: 0;
            width:35px;
            height: 35px;
            position: relative;
            margin-bottom: 0.5em;
            top: -10px;
            i{
                @include middle;
                margin: 0 !important;
            }
        }
    }

    &--form{
        background:$darkBlue;
        position:absolute;
        right:$gutter;
        top:auto;
        padding:1em;
        z-index: 200;
        display:none;


        input{
            border:none;
            padding:10px;
            background:transparent;
            color:$white;
        }

        button{
            @extend .c-btn;
            font-family: $bodyFont;
            color:$blue;
            font-weight: 700;
        }
    }
}