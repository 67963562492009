.c-mobile{
    &--toggle{
        display:inline-block;
        cursor: pointer;
        padding:0.5em 0.8em;
        margin: 1em 0;
        margin-right:1em;
        //width:30px;
        text-align:center;

        background-color: $gamma;
        border-radius: 5px;
        color: $white;
        text-transform: uppercase;
        letter-spacing: 1px;

        .far{
            margin-left: 0.5em;
            font-size:rem(22);
            color:$white;
        }

        @media all and (max-width:$md){
			font-size: 0;
			width:35px;
            height: 35px;
			position: relative;
			margin-bottom: 0.5em;
			top: -10px;
            i{
                @include middle;
                margin: 0 !important;
            }
        }
    }
}

.gblHeader{
	z-index:21;
}

.logo-dark{
    display: block;
}
.logo-light{
    display: none;
}

body.menu_open{
	overflow-y:scroll;
	#window{
		height:100vh;
		overflow:hidden;
    }
    .c-header{
        background-color: $gamma;
        z-index: 9999;
    }
    .c-search--toggle{
        display: none;
    }

    .logo-dark{
        display: none;
    }
    .logo-light{
        display: block;
    }
}

nav#menu{
    z-index:999;
	font-size:1.2em;
	font-weight:300;
	@extend %dpCover;
    background:$gamma;
    color: $white;
	display:none;
	overflow-y: scroll;

	top:44px;

	.closeBtn{
		display:none;
	}
	ul.mainNav{
		@extend %listless;
		position:absolute;
		top:0;
		right:0;
		width:100%;
		//height:100%;
		max-width:100vw;
		min-height: calc(100vh + 250px);
		//background:$t-blue;
		//padding:$pad;
		//padding-top:125px;
		padding-bottom: 10em;
		@include translateX(100%);
		@include transition(all,.3s);
		.touch &{
			overflow-y:scroll;
		}
		// @include bp_l{
		// 	padding-top:180px;
		// }
		// @include bp_m{
		// }
		// @include bp_s{
		// 	padding:30px;
		// 	padding-top:125px;
		// 	font-size:5vw;
		// }
		li{
            display: inline-block;
            width: 24%;
            padding: $gutter*2 0;
			padding-top: 120px;
			margin-top: 0 !important;
			font-size: 1.2em;
			font-weight: 500;
			//text-transform: uppercase;
			//letter-spacing: 2px;
			vertical-align: top;
            a{
                display: inline-block;
				width: 250px;
				//vertical-align: top;
				margin: 10px 0;
				color: $white;
                &:hover{
                    color: rgba($white, 0.6);
                }
            }
            &:last-of-type{
                border-bottom: 0;
            }

			@media all and (max-width:$md){
				width: 100%;
				padding-top: 60px;
			}
		}
		li+li{
			margin-top:1em;
		}
    }
    ul.subItems{
        //float: right;
        width: 75%;
        display: inline-block;
        li{
            display: block;
			width: 100%;
            border-bottom: 0;
            padding: 0;
            margin-right: 35px;
			font-size: 1rem;
			font-weight: 200;
            color: rgba($white,0.7);
			text-transform: none;
			letter-spacing: 0.5px;
            a{
                width: auto;
				top: 0;
				margin: 10px 0;
                &:hover{
                    color: rgba($white, 1)!important;
                }
				// @include bp_m{
				// 	display: block;
				// }
			}
		}
		// @include bp_m{
		// 	width: 100%;
		// 	padding:0;
		// 	padding-top: $gutter;
		// }
    }
	a{
        //@extend %noLink;
        @include transition(all,.2s);
	}
	.menu_open & ul{
		@include transition(all,.3s .3s);
		@include translateX(0%);
	}

	// @include bp_m{
	// 	top: 0;
	// }
}