.c-footer{
    background:$veryDarkBlue;
    padding:3em 0 2em;

    &--nav{
        li{
            margin-bottom:0.75em;
            
            a{
                text-transform:uppercase;
                font-size: rem(13);
            }
        }
    }

    &--contact{
        &--name{
            color:$blue;
            text-transform:uppercase;
            font-size:rem(13);
            font-weight: 400;
        }

        &--details{
            color:$newBlue;
            font-size:rem(13);
            line-height:2.4em;
            font-weight: 700;

            a{
                color:$newBlue;
                text-decoration:underline;
            }
        }
    }

    &--legal{
        margin-top:7em;
        color:$newBlue;
        font-size:rem(13);

        &--links{
            li{
                display:inline-block;
                padding-left:0.75em;

                a{
                    color:$newBlue;
                }
            }
        }
    }

    &--form{

        .umbraco-forms-fergusonFooter{
            display: none;
        }

        label{
            color:$blue;
            font-size:rem(14);
            font-weight: 700;
            margin-bottom:0.5em;
            display: inline-block;
        }

        &--field{
            background:$darkBlue;
            width:75%;

            input{
                background:transparent;
                border:none;
                width: calc(100% - 43px);
                padding: 10px 10px;
                display: inline-block;
                vertical-align: middle;
                color: $white;

                &:focus{
                    outline: none;
                }

                &.input-validation-error{
                    border:1px solid red;
                }
                
            }

            button{
                background:rgba($blue,0.5);
                border:none;
                display:inline-block;
                width:40px;
                height:40px;
                vertical-align: middle;
                cursor:pointer;

                .far{
                    font-size:rem(20);
                }

                &:hover{
                    background:$blue;
                }
            }

            
        }

        #signupValidiatorMsg{
            font-size:rem(13);
            color:$white;
        }
    }

    &--social{
        margin-top:1.5em;
    }

    .b.footer-logo-grid{
        margin-bottom:1rem;
        overflow:hidden;
        &+.b{
            clear:both;
        }
    }

    .list-footer-logos{
        @extend .gi;
        @extend .gi-m;
        &,
        li,
        img{
            display: block;
            float:left;
            position:relative;
            width:100%;
            padding:0;
            list-style:none;
        }
        li,
        img{
             margin: 0;
        }
        li{
            width:auto;
            display:inline-block;
            float:none;
            vertical-align:middle;
            margin:0 1em 1em 0;
        }
        img{
            width:auto;
            height:auto;
            max-width:280px;
            max-height:100px;
            @media screen and (max-width:400px){
                max-width:100%;
            }
        }
    }
}