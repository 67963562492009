.c-ships{
    @extend .o-padding;
}

.c-ship{

    &--image{
        position:relative;
        margin-bottom:1em;

        &--magnifier{
            position: absolute;
            bottom:0;
            right: 0;
            padding:0.5em 1em;
            background:$blue;
            color:$white;
            font-size:rem(17);
        }
    }

    &--status{
        position:absolute;
        top:0;
        left:0;
        right: 0;
        text-align:center;
        color:$white;
        font-size:rem(13);
        text-transform:uppercase;
        padding:0.5em 0;

        &.fitting{
            background:$blue;
        }

        &.construction{
            background:$darkBlue;
        }
    }

    &--name{
        font-size:rem(22);
        margin:0;
    }

    &--builtfor{
        color:$textColor;
    }
}