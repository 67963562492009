.o-padding{
    padding:2em 0;

    @media all and (min-width:$md){
        padding:4em 0;
    }

    &--top{
        padding-top:2em;

        @media all and (min-width:$md){
            padding-top:4em;
        }
    }

    &--small{
        padding:2em 0;
    }
}