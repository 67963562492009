/**
 * ELEMENTS.IMAGES
 *
 * @description : Basic image styles
 *
 */

/**
 *  Set images for responsive sizing
 */
img,
svg {
	display: block;
	max-width: 100%;
}
