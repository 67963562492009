/**
 * MASTER.SCSS
 *
 * @Project     : Ferguson Marine
 *
 */


// Set the viewport to resize responsively (similar to viewport meta tag)
@viewport {
	width: device-width;
	zoom: 1;
}

/**
 * 1. SETTINGS
 */
@import '1.settings/settings.base';
@import '1.settings/settings.breakpoints';
@import '1.settings/settings.colors';
@import '1.settings/settings.grid';
@import '1.settings/settings.fonts';


/**
 *  2. TOOLS
 */
@import '2.tools/tools.functions.scss';
@import '2.tools/tools.mixin.position.scss';
@import '2.tools/tools.mixin.clearfix.scss';
@import '2.tools/tools.mixin.baseline_grid.scss';
@import '2.tools/tools.mixins.scss';


/**
 * 3. GENERIC
 */
@import '3.generic/generic.reset';
@import '3.generic/generic.boxsizing';

/**
 *  4. ELEMENTS
 *  Basic styles for raw HTML elements
 *  No classes here
 */
@import '4.elements/elements.base';
@import '4.elements/elements.disabled';
@import '4.elements/elements.images';
@import '4.elements/elements.type';

/**
 *  5. OBJECTS
 *  These are repeating visual/structural patterns with no 'design' added
 *  Must be built with classes only
 */
@import '5.objects/objects.grid';
@import '5.objects/objects.layout';
@import '5.objects/objects.aligns';
@import '5.objects/objects.gradient';
@import '5.objects/objects.padding';

/**
 *  6. COMPONENTS
 *  A component is a reusable chunk of UI that is clearly designed, as opposed to an object.
 *  Clearly refers to a block of UI: pagination, buttons, modals, icons... etc
 *  You should be able to take a component and paste it anywhere in the app
 *  Must be built with classes only
 */

@import '6.components/components.canvas.scss';
@import '6.components/components.wrapper.scss';
@import '6.components/components.mobile.scss';
@import '6.components/components.buttons.scss';
@import '6.components/components.cookie.scss';
@import '6.components/components.header.scss';
@import '6.components/components.navigation.scss';
@import '6.components/components.search.scss';
@import '6.components/components.hero.scss';
@import '6.components/components.videobanner.scss';
@import '6.components/components.breadcrumbs.scss';
@import '6.components/components.introduction.scss';
@import '6.components/components.cards.scss';
@import '6.components/components.video.scss';
@import '6.components/components.tiles.scss';
@import '6.components/components.news.scss';
@import '6.components/components.pagination.scss';
@import '6.components/components.stayinformed.scss';
@import '6.components/components.footer.scss';
@import '6.components/components.forms.scss';
@import '6.components/components.textimage.scss';
@import '6.components/components.enquiry.scss';
@import '6.components/components.nextprevious.scss';
@import '6.components/components.twocolumns.scss';
@import '6.components/components.accordion.scss';
@import '6.components/components.ships.scss';
@import '6.components/components.history.scss';
@import '6.components/components.timeline.scss';
@import '6.components/components.team.scss';
@import '6.components/components.vacancy.scss';
@import '6.components/components.backtop.scss';
@import '6.components/components.sitemap.scss';

/**
 *  7. UTILITIES
 */
@import '7.utilities/utilities.layout';
@import '7.utilities/utilities.type';
