.c-hero{
    height:50vh;
    background-position:center;
    background-repeat: no-repeat;
    background-size:cover;
    padding-bottom:1em;

    a{
        color: $white;
        font-size: rem(40);
        i{
            margin-left: 1.2em;
            font-size: 1.8rem;
            @include transition-all(.2s);
        }

        &:hover{
            i{
                margin-left: 1.5em;
            }
        }
    }
    

    @media all and (min-width:$md){
        height:70vh;
        padding-bottom:4em;
    }

    .b, .g{
        height:100%;
    }

    &--heading{
        font-size:rem(40);
        color:$white;
        margin-bottom:0.2em;

        @media all and (min-width:$md){
            font-size:rem(70);
        }
    }

    &--subheading{
        color:$white;
        font-weight:400;
        font-size:rem(25);
        @media all and (min-width:$md){
            font-size:rem(35);
        }
    }

    &.nobg{
        height:auto;
        padding:3em 0 0.5em;
        

        .c-hero--heading{
            color:$darkBlue;
            font-size:rem(35);
            border-bottom:1px solid $mediumGrey;
            padding-bottom:1em;
        }
    }
}