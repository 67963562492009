.c-twocolumns{
    @extend .o-padding;

    &--heading{
        color:$darkBlue;
        margin-bottom:1.25em;
    }

    ul{
        margin-left:1em;

        li{
            list-style-type: disc;
            padding-left:0.5em;
            
            a{
                color:$darkBlue;
            }
        }
    }
}