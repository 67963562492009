/**
 * OBJECTS.LAYOUT
 *
 * @description : Core layout objects used in addition to the grid
 *
 */

/*  e.g.

    .o-page {
        padding-bottom: rem(40);
    }

*/

