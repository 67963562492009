.c-news{
    @extend .o-padding;

    &--heading{
        color:$darkBlue;
        //border-bottom:1px solid $mediumGrey;
        padding-bottom:0.5em;
        margin-bottom:1em;
    }

    &--header{
        position:relative;
    }

    &--filter{
        @media all and (min-width:$md){
            position: absolute;
            right:0;
            top:0;
        }

        li{
            display: inline-block;
            padding:0.5em 1em;
            cursor: pointer;

            a{
                color:$darkBlue;
            }

            &.selected{
                background:$blue;
                
                a{
                    color:$white;
                }
            }
        }

        &-select{
            position: absolute;
            right:0;
            top:0;

            background: #ffffff url(http://www.techandmath.gr/img/icons/small_down_arrow.gif) no-repeat 100% 50%;
            background-size: 5%;
            border-bottom:1px solid $gamma;
            float:left;
            padding:10px 0;
            overflow: hidden;
            width:260px;
            select{
                -webkit-appearance: none;
                border: none;
                box-shadow: none;
                background: transparent;
                background-image: none;
                font-size:18px;
                font-weight:normal;
                color: $gamma;
                //padding:0 0 0 20px;
                padding-bottom:12px\9;
                vertical-align:middle;
                width: 130%;
            }
        }
    }

    &--item{

        &--img{
            margin-bottom:0.75em;
            overflow:hidden;
            position:relative;
            border-radius: 25px;
            padding-top: 69.83%;
            
            &:after{
                content:"";
                position:absolute;
                top:0;
                bottom:0;
                left:0;
                right: 0;
                
            }

            img{

                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width:100%;
                transition:all 0.3s ease;
                object-fit: cover;
                border-radius: inherit;

                &[src*='noImage'], 
                &[src=''] {
                    background: #efefef;
                    font-size: 0;
                }
            }
        }
        
        &--heading{
            font-size:rem(25);
            font-weight:400;
            color:$gamma;
        }

        &--date {
            color: $gamma;
            text-transform: uppercase;
            text-decoration: none !important;

            @media screen and (min-width: 768px) {
                font-size: rem(18);
            }
        }

        &:hover{
            .c-news--item--heading{
                text-decoration:underline;
            }

            .c-news--item--img{
                &:after{
                    background:rgba($darkBlue,0.3);
                }
                img{
                    transform: scale(1.05, 1.05);
                }
            }
        }
    }
    p{
		font-size:rem(22);
	}

    //umbraco handlebar fix
    #news-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 1.5rem;
    }
}