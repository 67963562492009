.c-vacancy{
    @extend .o-padding;

    ul{
        margin-left:1em;

        li{
            list-style-type: disc;
            padding-left:0.5em;
            
            a{
                color:$darkBlue;
            }
        }
    }
    
    &--form{
        background:$lightGrey;
        padding:1em;

        @media all and (min-width:$md){
            padding:2em;
        }

        @media all and (min-width:$lg){
            padding:3em;
        }

        label{
            color:$black;
            text-transform:uppercase;
            font-size:rem(14);
            font-weight: 700;
        }

        input[type="text"],
        textarea{
            width:100%;
            background:$white;
            border:none;
            padding:10px;
        }

        textarea{
            min-height:250px;
        }

        .umbraco-forms-field {
            margin-bottom:1.5em;
        }

        .umbraco-forms-tooltip{
            display:block;
            font-weight: 700;
            font-size:rem(14);
        }

        input[type="submit"]{
            display:inline-block;
            background:$darkBlue;
            color:$white;
            border:none;
            padding:1em 2em;
            width:100%;
        }

        .uploadyourcv {
            
            .umbraco-forms-field-wrapper{
                position:relative;
                margin-bottom:0.75em;

                .fileupload{
                    cursor:pointer;
                    margin-top:-20px;

                    .uploadMsg{
                        //display:block;
                        font-weight: 700;
                        font-size:rem(14);
                        margin-left:0.5em;
                    }
                }
            }

            input[type="file"]{
                opacity:0;
                height:0;
            }            
        }
    }
}