.c-accordion{
    padding:4em 0;
    background:$lightGrey;

    &--item {
        background:$white;
        border-bottom:2px solid $lightGrey;
        position:relative;
        cursor:pointer;

        &:after{
            content:".";
            clear:both;
            display:block;
            height: 0;
            visibility:hidden;
        }

        &--image{
            width:35%;
            float:left;
            padding-bottom:35%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }

        &--text{
            padding:1em;
            width:65%;
            float:left;

            @media all and(min-width:$lg){
                padding:2em;
            }
        }

        &--heading{
            font-size:rem(22);

            @media all and (min-width:$md){
                font-size:rem(26);
            }
        }

        &--arrow{
            position: absolute;
            bottom:10px;
            left:40%;

            img{
                width:40px;
            }

            @media all and (min-width:$md){
                bottom:20px;

                img{
                    width:50px;
                }
            }
        }

        &--information{
            display:none;
        }

        &.open{
            .c-accordion--item--arrow{
                transform: rotate(180deg);
            }
        }

        &.unselected{
            opacity:0.75;
        }
    }

    &--list{
        margin-left:2em;

        li{
            list-style-type: disc;
            color:$darkBlue;
            padding-left:1em;
            
            a{
                color:$darkBlue;
            }
        }
    }

    &--expand{
        background:white;
        padding:1em;
        border-bottom:2px solid $lightGrey;
        display:none;

        @media all and (min-width:$md){
            padding:2em;
        }
    }
}