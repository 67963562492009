.c-tiles{
    a{
        border-radius: 25px;
    }
}
.c-tile{
    position:relative;
    max-height:370px;
    overflow:hidden;

    &:after{
        content:"";
        position:absolute;
        top:0;
        bottom:0;
        left:0;
        right: 0;
        background:rgba($black,0.1);
        border-radius: 25px;
    }

    &--heading{
        position: absolute;
        bottom:1em;
        left:1em;
        color:$white;
        margin:0;
        font-size:rem(33);
        z-index: 20;

        i{
            font-size: 1.3rem;
            margin-left: 1.3em;
            @include transition-all(.2s);
        }
    }

    &--image{
        overflow: hidden;
        height: 100%;
        border-radius: 25px;
        img{
            width:100%;
            height: 100%;
            transition:all 0.3s ease;        
        }
    }

    &:hover{

        &:after{
            background:rgba($darkBlue,0.3);
        }

        .c-tile--image{
            img{
                transform: scale(1.05, 1.05);
            }
        }

        i{
            margin-left: 1.5em;
        }
    }
}