@charset "UTF-8";
/**
 * MASTER.SCSS
 *
 * @Project     : Ferguson Marine
 *
 */
@viewport {
  width: device-width;
  zoom: 1; }

/**
 * 1. SETTINGS
 */
/**
 * SETTINGS.BASE
 *
 * @description : Key application variables and settings
 *
 */
/**
 * SETTINGS.BREAKPOINTS
 *
 * @description : Define major breakpioints
 *
 */
/**
    e.g.
    .thing {
        background: green;

        @include breakpoint($md){
            background: blue;
        }
    }
*/
/**
 * SETTINGS.COLORS
 *
 * @description : Color variable definitions
 *
 */
/**
 * Main Colours
 */
/**
 * UX/Validation colors
 */
/**
 * SETTINGS.GRID
 *
 * @description : Settings used in grid calculations
 *
 */
/**
 *  2. TOOLS
 */
/**
 * TOOLS.FUNCTIONS
 *
 * @description : Common functions
 *
 */
/**
 * Strip Unit
 *  @desc       : Strips the unit from a value to we can use it for calculations
 *  @source     : https://css-tricks.com/snippets/sass/strip-unit-function/
 *  @param      : $num - Number to remove unit from
 *  @return     : Unitless value
 */
/**
 *  REM Calc
 *  @desc      : Converts px values to REMs
 *  @param     : $val - The value to convert (usually passed in px but unit may be omitted)
 *  @param     : $base – Optional parameter, passed if you'd like to calculate with a different base font size
 *  @param     : $base-font-size – The default font size (defined in _settings.base.scss)
 *  @return    : $rems - rem-converted value
 */
/**
 *  Decimal-Round
 *  @desc      : Rounds a floating point number to the nearest whole number
 *  @param     : $number - The number to be rounded. must be unitless.
 */
/**
  *  Decimal-Floor
  *  @desc      : Rounds a floating point number down to the nearest whole number
  */
/**
 * TOOLS.MIXIN.POSITION
 *
 * @description :  Sets top, left, right and bottom values for an element
 *
 */
/**
 * TOOLS.FUNCTIONS.STRIPUNIT
 *
 * @description : Strips the unit from a value to we can use it for calculations
 * @source      : https://css-tricks.com/snippets/sass/strip-unit-function/
 *
 */
/**
 * TOOLS.MIXIN.BASELINE_GRID
 *
 * @description :  Adds a baseline grid for setting type.
 *                 Used in development only.
 *
 */
/**
 * @param $spacing    Set the desired line height of the grid. Defaults to the base line height
 */
nav#menu {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

nav#menu {
  width: 100%;
  height: 100%; }

nav#menu ul.mainNav {
  width: 100%; }
  nav#menu ul.mainNav,
  nav#menu ul.mainNav > li {
    margin: 0;
    padding: 0;
    list-style: none;
    display: block;
    float: left; }

/**
 * 3. GENERIC
 */
/**
 * GENERIC.RESET
 *
 * @description :  Include your desired reset here.
 *                 Default is Eric Meyer's reset
 *
 */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/**
 * GENERIC.BOXSIZING
 *
 * @description : Sets global box-sizing
 * @reference   : https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 *
 */
html {
  box-sizing: border-box; }

*, *::before, *::after {
  box-sizing: inherit; }

/**
 *  4. ELEMENTS
 *  Basic styles for raw HTML elements
 *  No classes here
 */
/**
 * ELEMENTS.BASE
 *
 * @description : Base element styles
 *
 */
html,
body {
  min-height: 100%;
  color: #333333;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  line-height: 1.8; }
  html.dev_type,
  body.dev_type {
    background-image: linear-gradient(to bottom, #0ff 0, rgba(255, 255, 255, 0) 1px);
    background-repeat: repeat-y;
    background-size: 100% 1.8; }

/**
 * ELEMENTS.DISABLED
 *
 * @description : Default styles for disabled elements
 *
 */
.disabled,
*:disabled,
[disabled] {
  cursor: default;
  opacity: 0.5;
  pointer-events: none; }

/**
 * ELEMENTS.IMAGES
 *
 * @description : Basic image styles
 *
 */
/**
 *  Set images for responsive sizing
 */
img,
svg {
  display: block;
  max-width: 100%; }

/**
 * ELEMENTS.TYPE
 *
 * @description : Basic typographical styles
 *
 */
h1, h2, h3, h4, h5, h6 {
  font-family: "Lato", sans-serif;
  line-height: 1.1;
  margin-bottom: 0.5em;
  font-weight: 500; }

h1 {
  font-size: 3.125rem; }

h2 {
  font-size: 2.5rem; }
  @media all and (max-width: 48em) {
    h2 {
      font-size: 2rem; } }

h3 {
  font-size: 1.75rem;
  font-weight: 400;
  color: #0D3657;
  margin: 0.80em 0; }

h4 {
  font-size: 1.375rem; }

h5 {
  font-size: 1.25rem; }

h6 {
  font-size: 1.3125rem; }

p, ul, ol {
  margin-bottom: 1em; }

ol li {
  list-style-type: decimal; }

strong {
  font-weight: 600; }

a {
  color: #4F8FC7;
  text-decoration: none; }
  a:hover {
    text-decoration: underline; }

/**
 *  5. OBJECTS
 *  These are repeating visual/structural patterns with no 'design' added
 *  Must be built with classes only
 */
/**
 * OBJECTS.GRID
 *
 * @settings    : See /1.settings/_settings.grid.scss
 *
 */
/** -------------------------------------------------------
 *  GRID WIDTH CALULATOR
 */
/**
 * General Styles
 */
*, *:before, *:after {
  box-sizing: border-box; }

.b {
  max-width: 82rem;
  margin-left: auto;
  margin-right: auto; }
  @media all and (max-width: 64em) {
    .b {
      padding-left: 3%;
      padding-right: 3%; } }

.b-s {
  max-width: 62rem; }

.b-m {
  max-width: 74rem; }

/**
 *  Helper classes for non-grid items so they fit nicely within the grid
 */
.ng-p {
  padding-left: 2.16667%;
  padding-right: 2.16667%; }

.ng-pr {
  padding-left: 0;
  padding-right: 2.16667%; }

.ng-pl {
  padding-left: 2.16667%;
  padding-right: 0; }

.ng-m {
  margin-left: 2.16667%;
  margin-right: 2.16667%; }

.ng-mr {
  margin-left: 0;
  margin-right: 2.16667%; }

.ng-ml {
  margin-left: 2.16667%;
  margin-right: 0; }

/**
 * The Grid
 */
.g {
  letter-spacing: -0.31em;
  *letter-spacing: normal;
  *word-spacing: -0.43em;
  text-rendering: optimizespeed;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .no-flexbox .g {
    display: table-cell;
    float: left;
    width: 100%; }
  .g-m {
    margin: 0 -2.16667%; }
  .g.g-c {
    justify-content: center; }
  .g.rtl {
    flex-direction: row-reverse; }

.opera-only :-o-prefocus,
.g {
  word-spacing: -0.43em; }

/** -------------------------
 *  GRID ITEMS
 */
.gi, .c-footer .list-footer-logos {
  display: inline-block;
  letter-spacing: normal;
  word-spacing: normal;
  text-rendering: auto;
  width: 100%; }

.gi-p {
  padding-left: 2.16667%;
  padding-right: 2.16667%; }

.gi-pr {
  padding-right: 2.16667%; }

.gi-pl {
  padding-left: 2.16667%; }

.gi-m, .c-footer .list-footer-logos {
  margin-left: 2.16667%;
  margin-right: 2.16667%; }

.gi-ml {
  margin-left: 2.16667%; }

.gi-mr {
  margin-right: 2.16667%; }

.g-vam {
  align-items: center; }

.g-vam .gi, .g-vam .c-footer .list-footer-logos, .c-footer .g-vam .list-footer-logos,
.g-vam .gi-p,
.g-vam .gi-pl,
.g-vam .gi-pr,
.g-vam .gi-m,
.g-vam .c-footer .list-footer-logos,
.c-footer .g-vam .list-footer-logos {
  vertical-align: middle; }

.g-vab {
  align-items: bottom; }

.g-vab .gi, .g-vab .c-footer .list-footer-logos, .c-footer .g-vab .list-footer-logos,
.g-vab .gi-p,
.g-vab .gi-pl,
.g-vab .gi-pr,
.g-vab .gi-m,
.g-vab .c-footer .list-footer-logos,
.c-footer .g-vab .list-footer-logos {
  align-self: flex-end; }

.gi-1 {
  width: 8.33333%; }

.gi-m.gi-1, .c-footer .gi-1.list-footer-logos {
  width: 4.0%; }

.gi-mr.gi-1, .gi-mr.gi-1 {
  width: 6.16667%; }

.gi-in-1 {
  margin-left: 8.33333%; }

.gi-m.gi-in-1, .c-footer .gi-in-1.list-footer-logos,
.gi-ml.gi-in-1 {
  margin-left: 10.5%; }

.gi-2 {
  width: 16.66667%; }

.gi-m.gi-2, .c-footer .gi-2.list-footer-logos {
  width: 12.33333%; }

.gi-mr.gi-2, .gi-mr.gi-2 {
  width: 14.5%; }

.gi-in-2 {
  margin-left: 16.66667%; }

.gi-m.gi-in-2, .c-footer .gi-in-2.list-footer-logos,
.gi-ml.gi-in-2 {
  margin-left: 18.83333%; }

.gi-3 {
  width: 25%; }

.gi-m.gi-3, .c-footer .gi-3.list-footer-logos {
  width: 20.66667%; }

.gi-mr.gi-3, .gi-mr.gi-3 {
  width: 22.83333%; }

.gi-in-3 {
  margin-left: 25%; }

.gi-m.gi-in-3, .c-footer .gi-in-3.list-footer-logos,
.gi-ml.gi-in-3 {
  margin-left: 27.16667%; }

.gi-4 {
  width: 33.33333%; }

.gi-m.gi-4, .c-footer .gi-4.list-footer-logos {
  width: 29.0%; }

.gi-mr.gi-4, .gi-mr.gi-4 {
  width: 31.16667%; }

.gi-in-4 {
  margin-left: 33.33333%; }

.gi-m.gi-in-4, .c-footer .gi-in-4.list-footer-logos,
.gi-ml.gi-in-4 {
  margin-left: 35.5%; }

.gi-5 {
  width: 41.66667%; }

.gi-m.gi-5, .c-footer .gi-5.list-footer-logos {
  width: 37.33333%; }

.gi-mr.gi-5, .gi-mr.gi-5 {
  width: 39.5%; }

.gi-in-5 {
  margin-left: 41.66667%; }

.gi-m.gi-in-5, .c-footer .gi-in-5.list-footer-logos,
.gi-ml.gi-in-5 {
  margin-left: 43.83333%; }

.gi-6 {
  width: 50%; }

.gi-m.gi-6, .c-footer .gi-6.list-footer-logos {
  width: 45.66667%; }

.gi-mr.gi-6, .gi-mr.gi-6 {
  width: 47.83333%; }

.gi-in-6 {
  margin-left: 50%; }

.gi-m.gi-in-6, .c-footer .gi-in-6.list-footer-logos,
.gi-ml.gi-in-6 {
  margin-left: 52.16667%; }

.gi-7 {
  width: 58.33333%; }

.gi-m.gi-7, .c-footer .gi-7.list-footer-logos {
  width: 54.0%; }

.gi-mr.gi-7, .gi-mr.gi-7 {
  width: 56.16667%; }

.gi-in-7 {
  margin-left: 58.33333%; }

.gi-m.gi-in-7, .c-footer .gi-in-7.list-footer-logos,
.gi-ml.gi-in-7 {
  margin-left: 60.5%; }

.gi-8 {
  width: 66.66667%; }

.gi-m.gi-8, .c-footer .gi-8.list-footer-logos {
  width: 62.33333%; }

.gi-mr.gi-8, .gi-mr.gi-8 {
  width: 64.5%; }

.gi-in-8 {
  margin-left: 66.66667%; }

.gi-m.gi-in-8, .c-footer .gi-in-8.list-footer-logos,
.gi-ml.gi-in-8 {
  margin-left: 68.83333%; }

.gi-9 {
  width: 75%; }

.gi-m.gi-9, .c-footer .gi-9.list-footer-logos {
  width: 70.66667%; }

.gi-mr.gi-9, .gi-mr.gi-9 {
  width: 72.83333%; }

.gi-in-9 {
  margin-left: 75%; }

.gi-m.gi-in-9, .c-footer .gi-in-9.list-footer-logos,
.gi-ml.gi-in-9 {
  margin-left: 77.16667%; }

.gi-10 {
  width: 83.33333%; }

.gi-m.gi-10, .c-footer .gi-10.list-footer-logos {
  width: 79.0%; }

.gi-mr.gi-10, .gi-mr.gi-10 {
  width: 81.16667%; }

.gi-in-10 {
  margin-left: 83.33333%; }

.gi-m.gi-in-10, .c-footer .gi-in-10.list-footer-logos,
.gi-ml.gi-in-10 {
  margin-left: 85.5%; }

.gi-11 {
  width: 91.66667%; }

.gi-m.gi-11, .c-footer .gi-11.list-footer-logos {
  width: 87.33333%; }

.gi-mr.gi-11, .gi-mr.gi-11 {
  width: 89.5%; }

.gi-in-11 {
  margin-left: 91.66667%; }

.gi-m.gi-in-11, .c-footer .gi-in-11.list-footer-logos,
.gi-ml.gi-in-11 {
  margin-left: 93.83333%; }

.gi-12 {
  width: 100%; }

.gi-m.gi-12, .c-footer .gi-12.list-footer-logos {
  width: 95.66667%; }

.gi-mr.gi-12, .gi-mr.gi-12 {
  width: 97.83333%; }

.gi-in-12 {
  margin-left: 100%; }

.gi-m.gi-in-12, .c-footer .gi-in-12.list-footer-logos,
.gi-ml.gi-in-12 {
  margin-left: 102.16667%; }

@media (min-width: 34.25em) {
  .gi-sm-1 {
    width: 8.33333%; }
  .gi-m.gi-sm-1, .c-footer .gi-sm-1.list-footer-logos {
    width: 4.0%; }
  .gi-ml.gi-sm-1,
  .gi-mr.gi-sm-1 {
    width: 6.16667%; }
  .gi-sm-in-1 {
    margin-left: 8.33333%; }
  .gi-m.gi-sm-in-1, .c-footer .gi-sm-in-1.list-footer-logos,
  .gi-ml.gi-sm-in-1 {
    margin-left: 10.5%; }
  .gi-sm-2 {
    width: 16.66667%; }
  .gi-m.gi-sm-2, .c-footer .gi-sm-2.list-footer-logos {
    width: 12.33333%; }
  .gi-ml.gi-sm-2,
  .gi-mr.gi-sm-2 {
    width: 14.5%; }
  .gi-sm-in-2 {
    margin-left: 16.66667%; }
  .gi-m.gi-sm-in-2, .c-footer .gi-sm-in-2.list-footer-logos,
  .gi-ml.gi-sm-in-2 {
    margin-left: 18.83333%; }
  .gi-sm-3 {
    width: 25%; }
  .gi-m.gi-sm-3, .c-footer .gi-sm-3.list-footer-logos {
    width: 20.66667%; }
  .gi-ml.gi-sm-3,
  .gi-mr.gi-sm-3 {
    width: 22.83333%; }
  .gi-sm-in-3 {
    margin-left: 25%; }
  .gi-m.gi-sm-in-3, .c-footer .gi-sm-in-3.list-footer-logos,
  .gi-ml.gi-sm-in-3 {
    margin-left: 27.16667%; }
  .gi-sm-4 {
    width: 33.33333%; }
  .gi-m.gi-sm-4, .c-footer .gi-sm-4.list-footer-logos {
    width: 29.0%; }
  .gi-ml.gi-sm-4,
  .gi-mr.gi-sm-4 {
    width: 31.16667%; }
  .gi-sm-in-4 {
    margin-left: 33.33333%; }
  .gi-m.gi-sm-in-4, .c-footer .gi-sm-in-4.list-footer-logos,
  .gi-ml.gi-sm-in-4 {
    margin-left: 35.5%; }
  .gi-sm-5 {
    width: 41.66667%; }
  .gi-m.gi-sm-5, .c-footer .gi-sm-5.list-footer-logos {
    width: 37.33333%; }
  .gi-ml.gi-sm-5,
  .gi-mr.gi-sm-5 {
    width: 39.5%; }
  .gi-sm-in-5 {
    margin-left: 41.66667%; }
  .gi-m.gi-sm-in-5, .c-footer .gi-sm-in-5.list-footer-logos,
  .gi-ml.gi-sm-in-5 {
    margin-left: 43.83333%; }
  .gi-sm-6 {
    width: 50%; }
  .gi-m.gi-sm-6, .c-footer .gi-sm-6.list-footer-logos {
    width: 45.66667%; }
  .gi-ml.gi-sm-6,
  .gi-mr.gi-sm-6 {
    width: 47.83333%; }
  .gi-sm-in-6 {
    margin-left: 50%; }
  .gi-m.gi-sm-in-6, .c-footer .gi-sm-in-6.list-footer-logos,
  .gi-ml.gi-sm-in-6 {
    margin-left: 52.16667%; }
  .gi-sm-7 {
    width: 58.33333%; }
  .gi-m.gi-sm-7, .c-footer .gi-sm-7.list-footer-logos {
    width: 54.0%; }
  .gi-ml.gi-sm-7,
  .gi-mr.gi-sm-7 {
    width: 56.16667%; }
  .gi-sm-in-7 {
    margin-left: 58.33333%; }
  .gi-m.gi-sm-in-7, .c-footer .gi-sm-in-7.list-footer-logos,
  .gi-ml.gi-sm-in-7 {
    margin-left: 60.5%; }
  .gi-sm-8 {
    width: 66.66667%; }
  .gi-m.gi-sm-8, .c-footer .gi-sm-8.list-footer-logos {
    width: 62.33333%; }
  .gi-ml.gi-sm-8,
  .gi-mr.gi-sm-8 {
    width: 64.5%; }
  .gi-sm-in-8 {
    margin-left: 66.66667%; }
  .gi-m.gi-sm-in-8, .c-footer .gi-sm-in-8.list-footer-logos,
  .gi-ml.gi-sm-in-8 {
    margin-left: 68.83333%; }
  .gi-sm-9 {
    width: 75%; }
  .gi-m.gi-sm-9, .c-footer .gi-sm-9.list-footer-logos {
    width: 70.66667%; }
  .gi-ml.gi-sm-9,
  .gi-mr.gi-sm-9 {
    width: 72.83333%; }
  .gi-sm-in-9 {
    margin-left: 75%; }
  .gi-m.gi-sm-in-9, .c-footer .gi-sm-in-9.list-footer-logos,
  .gi-ml.gi-sm-in-9 {
    margin-left: 77.16667%; }
  .gi-sm-10 {
    width: 83.33333%; }
  .gi-m.gi-sm-10, .c-footer .gi-sm-10.list-footer-logos {
    width: 79.0%; }
  .gi-ml.gi-sm-10,
  .gi-mr.gi-sm-10 {
    width: 81.16667%; }
  .gi-sm-in-10 {
    margin-left: 83.33333%; }
  .gi-m.gi-sm-in-10, .c-footer .gi-sm-in-10.list-footer-logos,
  .gi-ml.gi-sm-in-10 {
    margin-left: 85.5%; }
  .gi-sm-11 {
    width: 91.66667%; }
  .gi-m.gi-sm-11, .c-footer .gi-sm-11.list-footer-logos {
    width: 87.33333%; }
  .gi-ml.gi-sm-11,
  .gi-mr.gi-sm-11 {
    width: 89.5%; }
  .gi-sm-in-11 {
    margin-left: 91.66667%; }
  .gi-m.gi-sm-in-11, .c-footer .gi-sm-in-11.list-footer-logos,
  .gi-ml.gi-sm-in-11 {
    margin-left: 93.83333%; }
  .gi-sm-12 {
    width: 100%; }
  .gi-m.gi-sm-12, .c-footer .gi-sm-12.list-footer-logos {
    width: 95.66667%; }
  .gi-ml.gi-sm-12,
  .gi-mr.gi-sm-12 {
    width: 97.83333%; }
  .gi-sm-in-12 {
    margin-left: 100%; }
  .gi-m.gi-sm-in-12, .c-footer .gi-sm-in-12.list-footer-logos,
  .gi-ml.gi-sm-in-12 {
    margin-left: 102.16667%; }
  .gi-sm-pr {
    padding-left: 0;
    padding-right: 2.16667%; }
  .gi-sm-pl {
    padding-left: 2.16667%;
    padding-right: 0; } }

@media (min-width: 48em) {
  .gi-md-1 {
    width: 8.33333%; }
  .gi-m.gi-md-1, .c-footer .gi-md-1.list-footer-logos {
    width: 4.0%; }
  .gi-ml.gi-md-1,
  .gi-mr.gi-md-1 {
    width: 6.16667%; }
  .gi-md-in-1 {
    margin-left: 8.33333%; }
  .gi-m.gi-md-in-1, .c-footer .gi-md-in-1.list-footer-logos,
  .gi-ml.gi-md-in-1 {
    margin-left: 10.5%; }
  .gi-md-2 {
    width: 16.66667%; }
  .gi-m.gi-md-2, .c-footer .gi-md-2.list-footer-logos {
    width: 12.33333%; }
  .gi-ml.gi-md-2,
  .gi-mr.gi-md-2 {
    width: 14.5%; }
  .gi-md-in-2 {
    margin-left: 16.66667%; }
  .gi-m.gi-md-in-2, .c-footer .gi-md-in-2.list-footer-logos,
  .gi-ml.gi-md-in-2 {
    margin-left: 18.83333%; }
  .gi-md-3 {
    width: 25%; }
  .gi-m.gi-md-3, .c-footer .gi-md-3.list-footer-logos {
    width: 20.66667%; }
  .gi-ml.gi-md-3,
  .gi-mr.gi-md-3 {
    width: 22.83333%; }
  .gi-md-in-3 {
    margin-left: 25%; }
  .gi-m.gi-md-in-3, .c-footer .gi-md-in-3.list-footer-logos,
  .gi-ml.gi-md-in-3 {
    margin-left: 27.16667%; }
  .gi-md-4 {
    width: 33.33333%; }
  .gi-m.gi-md-4, .c-footer .gi-md-4.list-footer-logos {
    width: 29.0%; }
  .gi-ml.gi-md-4,
  .gi-mr.gi-md-4 {
    width: 31.16667%; }
  .gi-md-in-4 {
    margin-left: 33.33333%; }
  .gi-m.gi-md-in-4, .c-footer .gi-md-in-4.list-footer-logos,
  .gi-ml.gi-md-in-4 {
    margin-left: 35.5%; }
  .gi-md-5 {
    width: 41.66667%; }
  .gi-m.gi-md-5, .c-footer .gi-md-5.list-footer-logos {
    width: 37.33333%; }
  .gi-ml.gi-md-5,
  .gi-mr.gi-md-5 {
    width: 39.5%; }
  .gi-md-in-5 {
    margin-left: 41.66667%; }
  .gi-m.gi-md-in-5, .c-footer .gi-md-in-5.list-footer-logos,
  .gi-ml.gi-md-in-5 {
    margin-left: 43.83333%; }
  .gi-md-6 {
    width: 50%; }
  .gi-m.gi-md-6, .c-footer .gi-md-6.list-footer-logos {
    width: 45.66667%; }
  .gi-ml.gi-md-6,
  .gi-mr.gi-md-6 {
    width: 47.83333%; }
  .gi-md-in-6 {
    margin-left: 50%; }
  .gi-m.gi-md-in-6, .c-footer .gi-md-in-6.list-footer-logos,
  .gi-ml.gi-md-in-6 {
    margin-left: 52.16667%; }
  .gi-md-7 {
    width: 58.33333%; }
  .gi-m.gi-md-7, .c-footer .gi-md-7.list-footer-logos {
    width: 54.0%; }
  .gi-ml.gi-md-7,
  .gi-mr.gi-md-7 {
    width: 56.16667%; }
  .gi-md-in-7 {
    margin-left: 58.33333%; }
  .gi-m.gi-md-in-7, .c-footer .gi-md-in-7.list-footer-logos,
  .gi-ml.gi-md-in-7 {
    margin-left: 60.5%; }
  .gi-md-8 {
    width: 66.66667%; }
  .gi-m.gi-md-8, .c-footer .gi-md-8.list-footer-logos {
    width: 62.33333%; }
  .gi-ml.gi-md-8,
  .gi-mr.gi-md-8 {
    width: 64.5%; }
  .gi-md-in-8 {
    margin-left: 66.66667%; }
  .gi-m.gi-md-in-8, .c-footer .gi-md-in-8.list-footer-logos,
  .gi-ml.gi-md-in-8 {
    margin-left: 68.83333%; }
  .gi-md-9 {
    width: 75%; }
  .gi-m.gi-md-9, .c-footer .gi-md-9.list-footer-logos {
    width: 70.66667%; }
  .gi-ml.gi-md-9,
  .gi-mr.gi-md-9 {
    width: 72.83333%; }
  .gi-md-in-9 {
    margin-left: 75%; }
  .gi-m.gi-md-in-9, .c-footer .gi-md-in-9.list-footer-logos,
  .gi-ml.gi-md-in-9 {
    margin-left: 77.16667%; }
  .gi-md-10 {
    width: 83.33333%; }
  .gi-m.gi-md-10, .c-footer .gi-md-10.list-footer-logos {
    width: 79.0%; }
  .gi-ml.gi-md-10,
  .gi-mr.gi-md-10 {
    width: 81.16667%; }
  .gi-md-in-10 {
    margin-left: 83.33333%; }
  .gi-m.gi-md-in-10, .c-footer .gi-md-in-10.list-footer-logos,
  .gi-ml.gi-md-in-10 {
    margin-left: 85.5%; }
  .gi-md-11 {
    width: 91.66667%; }
  .gi-m.gi-md-11, .c-footer .gi-md-11.list-footer-logos {
    width: 87.33333%; }
  .gi-ml.gi-md-11,
  .gi-mr.gi-md-11 {
    width: 89.5%; }
  .gi-md-in-11 {
    margin-left: 91.66667%; }
  .gi-m.gi-md-in-11, .c-footer .gi-md-in-11.list-footer-logos,
  .gi-ml.gi-md-in-11 {
    margin-left: 93.83333%; }
  .gi-md-12 {
    width: 100%; }
  .gi-m.gi-md-12, .c-footer .gi-md-12.list-footer-logos {
    width: 95.66667%; }
  .gi-ml.gi-md-12,
  .gi-mr.gi-md-12 {
    width: 97.83333%; }
  .gi-md-in-12 {
    margin-left: 100%; }
  .gi-m.gi-md-in-12, .c-footer .gi-md-in-12.list-footer-logos,
  .gi-ml.gi-md-in-12 {
    margin-left: 102.16667%; }
  .gi-md-pr {
    padding-left: 0;
    padding-right: 2.16667%; }
  .gi-md-pl {
    padding-left: 2.16667%;
    padding-right: 0; } }

@media (min-width: 64em) {
  .gi-lg-1 {
    width: 8.33333%; }
  .gi-m.gi-lg-1, .c-footer .gi-lg-1.list-footer-logos {
    width: 4.0%; }
  .gi-ml.gi-lg-1,
  .gi-mr.gi-lg-1 {
    width: 6.16667%; }
  .gi-lg-in-1 {
    margin-left: 8.33333%; }
  .gi-m.gi-lg-in-1, .c-footer .gi-lg-in-1.list-footer-logos,
  .gi-ml.gi-lg-in-1 {
    margin-left: 10.5%; }
  .gi-lg-2 {
    width: 16.66667%; }
  .gi-m.gi-lg-2, .c-footer .gi-lg-2.list-footer-logos {
    width: 12.33333%; }
  .gi-ml.gi-lg-2,
  .gi-mr.gi-lg-2 {
    width: 14.5%; }
  .gi-lg-in-2 {
    margin-left: 16.66667%; }
  .gi-m.gi-lg-in-2, .c-footer .gi-lg-in-2.list-footer-logos,
  .gi-ml.gi-lg-in-2 {
    margin-left: 18.83333%; }
  .gi-lg-3 {
    width: 25%; }
  .gi-m.gi-lg-3, .c-footer .gi-lg-3.list-footer-logos {
    width: 20.66667%; }
  .gi-ml.gi-lg-3,
  .gi-mr.gi-lg-3 {
    width: 22.83333%; }
  .gi-lg-in-3 {
    margin-left: 25%; }
  .gi-m.gi-lg-in-3, .c-footer .gi-lg-in-3.list-footer-logos,
  .gi-ml.gi-lg-in-3 {
    margin-left: 27.16667%; }
  .gi-lg-4 {
    width: 33.33333%; }
  .gi-m.gi-lg-4, .c-footer .gi-lg-4.list-footer-logos {
    width: 29.0%; }
  .gi-ml.gi-lg-4,
  .gi-mr.gi-lg-4 {
    width: 31.16667%; }
  .gi-lg-in-4 {
    margin-left: 33.33333%; }
  .gi-m.gi-lg-in-4, .c-footer .gi-lg-in-4.list-footer-logos,
  .gi-ml.gi-lg-in-4 {
    margin-left: 35.5%; }
  .gi-lg-5 {
    width: 41.66667%; }
  .gi-m.gi-lg-5, .c-footer .gi-lg-5.list-footer-logos {
    width: 37.33333%; }
  .gi-ml.gi-lg-5,
  .gi-mr.gi-lg-5 {
    width: 39.5%; }
  .gi-lg-in-5 {
    margin-left: 41.66667%; }
  .gi-m.gi-lg-in-5, .c-footer .gi-lg-in-5.list-footer-logos,
  .gi-ml.gi-lg-in-5 {
    margin-left: 43.83333%; }
  .gi-lg-6 {
    width: 50%; }
  .gi-m.gi-lg-6, .c-footer .gi-lg-6.list-footer-logos {
    width: 45.66667%; }
  .gi-ml.gi-lg-6,
  .gi-mr.gi-lg-6 {
    width: 47.83333%; }
  .gi-lg-in-6 {
    margin-left: 50%; }
  .gi-m.gi-lg-in-6, .c-footer .gi-lg-in-6.list-footer-logos,
  .gi-ml.gi-lg-in-6 {
    margin-left: 52.16667%; }
  .gi-lg-7 {
    width: 58.33333%; }
  .gi-m.gi-lg-7, .c-footer .gi-lg-7.list-footer-logos {
    width: 54.0%; }
  .gi-ml.gi-lg-7,
  .gi-mr.gi-lg-7 {
    width: 56.16667%; }
  .gi-lg-in-7 {
    margin-left: 58.33333%; }
  .gi-m.gi-lg-in-7, .c-footer .gi-lg-in-7.list-footer-logos,
  .gi-ml.gi-lg-in-7 {
    margin-left: 60.5%; }
  .gi-lg-8 {
    width: 66.66667%; }
  .gi-m.gi-lg-8, .c-footer .gi-lg-8.list-footer-logos {
    width: 62.33333%; }
  .gi-ml.gi-lg-8,
  .gi-mr.gi-lg-8 {
    width: 64.5%; }
  .gi-lg-in-8 {
    margin-left: 66.66667%; }
  .gi-m.gi-lg-in-8, .c-footer .gi-lg-in-8.list-footer-logos,
  .gi-ml.gi-lg-in-8 {
    margin-left: 68.83333%; }
  .gi-lg-9 {
    width: 75%; }
  .gi-m.gi-lg-9, .c-footer .gi-lg-9.list-footer-logos {
    width: 70.66667%; }
  .gi-ml.gi-lg-9,
  .gi-mr.gi-lg-9 {
    width: 72.83333%; }
  .gi-lg-in-9 {
    margin-left: 75%; }
  .gi-m.gi-lg-in-9, .c-footer .gi-lg-in-9.list-footer-logos,
  .gi-ml.gi-lg-in-9 {
    margin-left: 77.16667%; }
  .gi-lg-10 {
    width: 83.33333%; }
  .gi-m.gi-lg-10, .c-footer .gi-lg-10.list-footer-logos {
    width: 79.0%; }
  .gi-ml.gi-lg-10,
  .gi-mr.gi-lg-10 {
    width: 81.16667%; }
  .gi-lg-in-10 {
    margin-left: 83.33333%; }
  .gi-m.gi-lg-in-10, .c-footer .gi-lg-in-10.list-footer-logos,
  .gi-ml.gi-lg-in-10 {
    margin-left: 85.5%; }
  .gi-lg-11 {
    width: 91.66667%; }
  .gi-m.gi-lg-11, .c-footer .gi-lg-11.list-footer-logos {
    width: 87.33333%; }
  .gi-ml.gi-lg-11,
  .gi-mr.gi-lg-11 {
    width: 89.5%; }
  .gi-lg-in-11 {
    margin-left: 91.66667%; }
  .gi-m.gi-lg-in-11, .c-footer .gi-lg-in-11.list-footer-logos,
  .gi-ml.gi-lg-in-11 {
    margin-left: 93.83333%; }
  .gi-lg-12 {
    width: 100%; }
  .gi-m.gi-lg-12, .c-footer .gi-lg-12.list-footer-logos {
    width: 95.66667%; }
  .gi-ml.gi-lg-12,
  .gi-mr.gi-lg-12 {
    width: 97.83333%; }
  .gi-lg-in-12 {
    margin-left: 100%; }
  .gi-m.gi-lg-in-12, .c-footer .gi-lg-in-12.list-footer-logos,
  .gi-ml.gi-lg-in-12 {
    margin-left: 102.16667%; }
  .gi-lg-pr {
    padding-left: 0;
    padding-right: 2.16667%; }
  .gi-lg-pl {
    padding-left: 2.16667%;
    padding-right: 0; } }

@media (min-width: 90em) {
  .gi-xl-1 {
    width: 8.33333%; }
  .gi-m.gi-xl-1, .c-footer .gi-xl-1.list-footer-logos {
    width: 4.0%; }
  .gi-ml.gi-xl-1,
  .gi-mr.gi-xl-1 {
    width: 6.16667%; }
  .gi-xl-in-1 {
    margin-left: 8.33333%; }
  .gi-m.gi-xl-in-1, .c-footer .gi-xl-in-1.list-footer-logos,
  .gi-ml.gi-xl-in-1 {
    margin-left: 10.5%; }
  .gi-xl-2 {
    width: 16.66667%; }
  .gi-m.gi-xl-2, .c-footer .gi-xl-2.list-footer-logos {
    width: 12.33333%; }
  .gi-ml.gi-xl-2,
  .gi-mr.gi-xl-2 {
    width: 14.5%; }
  .gi-xl-in-2 {
    margin-left: 16.66667%; }
  .gi-m.gi-xl-in-2, .c-footer .gi-xl-in-2.list-footer-logos,
  .gi-ml.gi-xl-in-2 {
    margin-left: 18.83333%; }
  .gi-xl-3 {
    width: 25%; }
  .gi-m.gi-xl-3, .c-footer .gi-xl-3.list-footer-logos {
    width: 20.66667%; }
  .gi-ml.gi-xl-3,
  .gi-mr.gi-xl-3 {
    width: 22.83333%; }
  .gi-xl-in-3 {
    margin-left: 25%; }
  .gi-m.gi-xl-in-3, .c-footer .gi-xl-in-3.list-footer-logos,
  .gi-ml.gi-xl-in-3 {
    margin-left: 27.16667%; }
  .gi-xl-4 {
    width: 33.33333%; }
  .gi-m.gi-xl-4, .c-footer .gi-xl-4.list-footer-logos {
    width: 29.0%; }
  .gi-ml.gi-xl-4,
  .gi-mr.gi-xl-4 {
    width: 31.16667%; }
  .gi-xl-in-4 {
    margin-left: 33.33333%; }
  .gi-m.gi-xl-in-4, .c-footer .gi-xl-in-4.list-footer-logos,
  .gi-ml.gi-xl-in-4 {
    margin-left: 35.5%; }
  .gi-xl-5 {
    width: 41.66667%; }
  .gi-m.gi-xl-5, .c-footer .gi-xl-5.list-footer-logos {
    width: 37.33333%; }
  .gi-ml.gi-xl-5,
  .gi-mr.gi-xl-5 {
    width: 39.5%; }
  .gi-xl-in-5 {
    margin-left: 41.66667%; }
  .gi-m.gi-xl-in-5, .c-footer .gi-xl-in-5.list-footer-logos,
  .gi-ml.gi-xl-in-5 {
    margin-left: 43.83333%; }
  .gi-xl-6 {
    width: 50%; }
  .gi-m.gi-xl-6, .c-footer .gi-xl-6.list-footer-logos {
    width: 45.66667%; }
  .gi-ml.gi-xl-6,
  .gi-mr.gi-xl-6 {
    width: 47.83333%; }
  .gi-xl-in-6 {
    margin-left: 50%; }
  .gi-m.gi-xl-in-6, .c-footer .gi-xl-in-6.list-footer-logos,
  .gi-ml.gi-xl-in-6 {
    margin-left: 52.16667%; }
  .gi-xl-7 {
    width: 58.33333%; }
  .gi-m.gi-xl-7, .c-footer .gi-xl-7.list-footer-logos {
    width: 54.0%; }
  .gi-ml.gi-xl-7,
  .gi-mr.gi-xl-7 {
    width: 56.16667%; }
  .gi-xl-in-7 {
    margin-left: 58.33333%; }
  .gi-m.gi-xl-in-7, .c-footer .gi-xl-in-7.list-footer-logos,
  .gi-ml.gi-xl-in-7 {
    margin-left: 60.5%; }
  .gi-xl-8 {
    width: 66.66667%; }
  .gi-m.gi-xl-8, .c-footer .gi-xl-8.list-footer-logos {
    width: 62.33333%; }
  .gi-ml.gi-xl-8,
  .gi-mr.gi-xl-8 {
    width: 64.5%; }
  .gi-xl-in-8 {
    margin-left: 66.66667%; }
  .gi-m.gi-xl-in-8, .c-footer .gi-xl-in-8.list-footer-logos,
  .gi-ml.gi-xl-in-8 {
    margin-left: 68.83333%; }
  .gi-xl-9 {
    width: 75%; }
  .gi-m.gi-xl-9, .c-footer .gi-xl-9.list-footer-logos {
    width: 70.66667%; }
  .gi-ml.gi-xl-9,
  .gi-mr.gi-xl-9 {
    width: 72.83333%; }
  .gi-xl-in-9 {
    margin-left: 75%; }
  .gi-m.gi-xl-in-9, .c-footer .gi-xl-in-9.list-footer-logos,
  .gi-ml.gi-xl-in-9 {
    margin-left: 77.16667%; }
  .gi-xl-10 {
    width: 83.33333%; }
  .gi-m.gi-xl-10, .c-footer .gi-xl-10.list-footer-logos {
    width: 79.0%; }
  .gi-ml.gi-xl-10,
  .gi-mr.gi-xl-10 {
    width: 81.16667%; }
  .gi-xl-in-10 {
    margin-left: 83.33333%; }
  .gi-m.gi-xl-in-10, .c-footer .gi-xl-in-10.list-footer-logos,
  .gi-ml.gi-xl-in-10 {
    margin-left: 85.5%; }
  .gi-xl-11 {
    width: 91.66667%; }
  .gi-m.gi-xl-11, .c-footer .gi-xl-11.list-footer-logos {
    width: 87.33333%; }
  .gi-ml.gi-xl-11,
  .gi-mr.gi-xl-11 {
    width: 89.5%; }
  .gi-xl-in-11 {
    margin-left: 91.66667%; }
  .gi-m.gi-xl-in-11, .c-footer .gi-xl-in-11.list-footer-logos,
  .gi-ml.gi-xl-in-11 {
    margin-left: 93.83333%; }
  .gi-xl-12 {
    width: 100%; }
  .gi-m.gi-xl-12, .c-footer .gi-xl-12.list-footer-logos {
    width: 95.66667%; }
  .gi-ml.gi-xl-12,
  .gi-mr.gi-xl-12 {
    width: 97.83333%; }
  .gi-xl-in-12 {
    margin-left: 100%; }
  .gi-m.gi-xl-in-12, .c-footer .gi-xl-in-12.list-footer-logos,
  .gi-ml.gi-xl-in-12 {
    margin-left: 102.16667%; }
  .gi-xl-pr {
    padding-left: 0;
    padding-right: 2.16667%; }
  .gi-xl-pl {
    padding-left: 2.16667%;
    padding-right: 0; } }

/**
 * OBJECTS.LAYOUT
 *
 * @description : Core layout objects used in addition to the grid
 *
 */
/*  e.g.

    .o-page {
        padding-bottom: rem(40);
    }

*/
.align-left {
  text-align: left; }

.align-center {
  text-align: center; }

.align-right {
  text-align: right; }

@media (min-width: 34.25em) {
  .align-left-sm {
    text-align: left; }
  .align-center-sm {
    text-align: center; }
  .align-right-sm {
    text-align: right; } }

@media (min-width: 48em) {
  .align-left-md {
    text-align: left; }
  .align-center-md {
    text-align: center; }
  .align-right-md {
    text-align: right; } }

@media (min-width: 64em) {
  .align-left-lg {
    text-align: left; }
  .align-center-lg {
    text-align: center; }
  .align-right-lg {
    text-align: right; } }

@media (min-width: 90em) {
  .align-left-xl {
    text-align: left; }
  .align-center-xl {
    text-align: center; }
  .align-right-xl {
    text-align: right; } }

.o-gradient, .c-btn.upload, .c-search--form button.upload {
  background: #FFFFFF;
  /* Old browsers */
  background: linear-gradient(to bottom, #FFFFFF 0%, #FFFFFF 50%, #F4F4F4 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }

.o-padding, .c-introduction, .c-cards, .c-video, .c-news, .c-stayinformed, .c-textimage, .c-enquiry, .c-nextprevious, .c-twocolumns, .c-ships, .c-team, .c-vacancy {
  padding: 2em 0; }
  @media all and (min-width: 48em) {
    .o-padding, .c-introduction, .c-cards, .c-video, .c-news, .c-stayinformed, .c-textimage, .c-enquiry, .c-nextprevious, .c-twocolumns, .c-ships, .c-team, .c-vacancy {
      padding: 4em 0; } }
  .o-padding--top {
    padding-top: 2em; }
    @media all and (min-width: 48em) {
      .o-padding--top {
        padding-top: 4em; } }
  .o-padding--small, .c-sitemap {
    padding: 2em 0; }

/**
 *  6. COMPONENTS
 *  A component is a reusable chunk of UI that is clearly designed, as opposed to an object.
 *  Clearly refers to a block of UI: pagination, buttons, modals, icons... etc
 *  You should be able to take a component and paste it anywhere in the app
 *  Must be built with classes only
 */
.c-canvas {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .c-canvas.open--nav .c-wrapper {
    -webkit-transform: translateX(-280px);
    transform: translateX(-280px); }

.c-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: .3s ease all;
  transition: .3s ease all; }

.c-mobile--toggle {
  display: inline-block;
  cursor: pointer;
  padding: 0.5em 0.8em;
  margin: 1em 0;
  margin-right: 1em;
  text-align: center;
  background-color: #0D3657;
  border-radius: 5px;
  color: #FFFFFF;
  text-transform: uppercase;
  letter-spacing: 1px; }
  .c-mobile--toggle .far {
    margin-left: 0.5em;
    font-size: 1.375rem;
    color: #FFFFFF; }
  @media all and (max-width: 48em) {
    .c-mobile--toggle {
      font-size: 0;
      width: 35px;
      height: 35px;
      position: relative;
      margin-bottom: 0.5em;
      top: -10px; }
      .c-mobile--toggle i {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        margin: 0 !important; } }

.gblHeader {
  z-index: 21; }

.logo-dark {
  display: block; }

.logo-light {
  display: none; }

body.menu_open {
  overflow-y: scroll; }
  body.menu_open #window {
    height: 100vh;
    overflow: hidden; }
  body.menu_open .c-header {
    background-color: #0D3657;
    z-index: 9999; }
  body.menu_open .c-search--toggle {
    display: none; }
  body.menu_open .logo-dark {
    display: none; }
  body.menu_open .logo-light {
    display: block; }

nav#menu {
  z-index: 999;
  font-size: 1.2em;
  font-weight: 300;
  background: #0D3657;
  color: #FFFFFF;
  display: none;
  overflow-y: scroll;
  top: 44px; }
  nav#menu .closeBtn {
    display: none; }
  nav#menu ul.mainNav {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 100vw;
    min-height: calc(100vh + 250px);
    padding-bottom: 10em;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out; }
    .touch nav#menu ul.mainNav {
      overflow-y: scroll; }
    nav#menu ul.mainNav li {
      display: inline-block;
      width: 24%;
      padding: 4.33333% 0;
      padding-top: 120px;
      margin-top: 0 !important;
      font-size: 1.2em;
      font-weight: 500;
      vertical-align: top; }
      nav#menu ul.mainNav li a {
        display: inline-block;
        width: 250px;
        margin: 10px 0;
        color: #FFFFFF; }
        nav#menu ul.mainNav li a:hover {
          color: rgba(255, 255, 255, 0.6); }
      nav#menu ul.mainNav li:last-of-type {
        border-bottom: 0; }
      @media all and (max-width: 48em) {
        nav#menu ul.mainNav li {
          width: 100%;
          padding-top: 60px; } }
    nav#menu ul.mainNav li + li {
      margin-top: 1em; }
  nav#menu ul.subItems {
    width: 75%;
    display: inline-block; }
    nav#menu ul.subItems li {
      display: block;
      width: 100%;
      border-bottom: 0;
      padding: 0;
      margin-right: 35px;
      font-size: 1rem;
      font-weight: 200;
      color: rgba(255, 255, 255, 0.7);
      text-transform: none;
      letter-spacing: 0.5px; }
      nav#menu ul.subItems li a {
        width: auto;
        top: 0;
        margin: 10px 0; }
        nav#menu ul.subItems li a:hover {
          color: white !important; }
  nav#menu a {
    transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out; }
  .menu_open nav#menu ul {
    transition: all 0.3s 0.3s ease-in-out;
    -ms-transition: all 0.3s 0.3s ease-in-out;
    -o-transition: all 0.3s 0.3s ease-in-out;
    -webkit-transition: all 0.3s 0.3s ease-in-out;
    -moz-transition: all 0.3s 0.3s ease-in-out;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%); }

.c-btn, .c-search--form button {
  display: inline-block;
  background: #0D3657;
  color: #FFFFFF;
  border: none;
  padding: 1em 2em;
  letter-spacing: 1px;
  border-radius: 5px;
  text-transform: uppercase; }
  .c-btn--filled {
    background: #FFFFFF;
    color: #4F8FC7;
    border: 2px solid #E3E3E3;
    text-transform: uppercase; }
    .c-btn--filled:hover {
      background: #4F8FC7;
      color: #FFFFFF;
      border-color: #4F8FC7;
      text-decoration: none; }
  .c-btn.upload, .c-search--form button.upload {
    padding: 0.35em 2em;
    color: #000000;
    font-weight: 700;
    font-size: 0.875rem;
    border: 1px solid #999999; }

.c-cookie {
  background: #F4F4F4;
  padding: 1em;
  display: none; }
  .c-cookie p {
    font-size: 0.8125rem;
    margin: 0; }

.c-header {
  position: relative;
  z-index: 20;
  background: #FFFFFF;
  min-height: 44px; }
  .c-header .b {
    position: relative; }
  .c-header--logo {
    width: 75%; }

@media all and (max-width: 48em) {
  .c-navigation {
    position: fixed;
    top: 0;
    width: 280px;
    right: -280px;
    background: #0D3657;
    z-index: 2000;
    height: 100%;
    text-align: left;
    padding: 2em; }
    .c-navigation ul li {
      padding: 0.5em 0; }
      .c-navigation ul li a {
        color: #FFFFFF;
        text-transform: uppercase;
        font-size: 1.0625rem; } }

@media all and (min-width: 48em) {
  .c-navigation {
    display: inline-block; }
    .c-navigation ul {
      margin: 0;
      padding: 0;
      display: inline-block; }
      .c-navigation ul li {
        display: inline-block;
        padding: 0 0.5em; } }
      @media all and (min-width: 48em) and (min-width: 64em) {
        .c-navigation ul li {
          padding: 0 0.75em; } }

@media all and (min-width: 48em) {
        .c-navigation ul li a {
          font-weight: 700;
          display: block;
          padding: 1.1em 0;
          border-bottom: 3px solid transparent; }
        .c-navigation ul li:hover a {
          border-color: #E3E3E3;
          text-decoration: none; }
        .c-navigation ul li.selected a {
          border-color: #4F8FC7; } }

.c-search--results {
  font-weight: 700;
  font-size: 1.125rem;
  color: #999999; }
  .c-search--results span {
    font-style: italic;
    color: #0D3657; }

.c-search--bar {
  border-bottom: 1px solid #E3E3E3;
  padding-bottom: 1em; }
  .c-search--bar p {
    margin: 0; }
  .c-search--bar.bottom {
    border-top: 1px solid #E3E3E3;
    padding-top: 1em;
    margin-bottom: 3em;
    border-bottom: none; }
  .c-search--bar--link {
    font-size: 1.125rem; }

.c-search--again {
  background: #E3E3E3;
  width: 75%;
  display: inline-block; }
  .c-search--again input {
    float: left;
    background: transparent;
    border: none;
    width: calc(100% - 43px);
    padding: 10px 10px;
    display: inline-block;
    vertical-align: middle;
    color: #000000; }
    .c-search--again input:focus {
      outline: none; }
  .c-search--again button {
    background: #4F8FC7;
    border: none;
    display: inline-block;
    width: 40px;
    height: 40px;
    vertical-align: middle;
    cursor: pointer; }
    .c-search--again button .far {
      font-size: 1.25rem;
      color: #FFFFFF; }
    .c-search--again button:hover {
      background: #0D3657; }

.c-search--toggle {
  display: inline-block;
  cursor: pointer;
  padding: 0.5em 0.8em;
  margin-right: 1em;
  text-align: center;
  background-color: #999999;
  border-radius: 5px;
  color: #FFFFFF;
  text-transform: uppercase;
  letter-spacing: 1px; }
  .c-search--toggle .far {
    margin-left: 0.5em;
    color: #FFFFFF;
    font-size: 1.125rem; }
  @media all and (max-width: 48em) {
    .c-search--toggle {
      font-size: 0;
      width: 35px;
      height: 35px;
      position: relative;
      margin-bottom: 0.5em;
      top: -10px; }
      .c-search--toggle i {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        margin: 0 !important; } }

.c-search--form {
  background: #0D3657;
  position: absolute;
  right: 2.16667%;
  top: auto;
  padding: 1em;
  z-index: 200;
  display: none; }
  .c-search--form input {
    border: none;
    padding: 10px;
    background: transparent;
    color: #FFFFFF; }
  .c-search--form button {
    font-family: "Lato", sans-serif;
    color: #4F8FC7;
    font-weight: 700; }

.c-hero {
  height: 50vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 1em; }
  .c-hero a {
    color: #FFFFFF;
    font-size: 2.5rem; }
    .c-hero a i {
      margin-left: 1.2em;
      font-size: 1.8rem;
      transition: all 0.2s ease-in-out;
      -ms-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out; }
    .c-hero a:hover i {
      margin-left: 1.5em; }
  @media all and (min-width: 48em) {
    .c-hero {
      height: 70vh;
      padding-bottom: 4em; } }
  .c-hero .b, .c-hero .g {
    height: 100%; }
  .c-hero--heading {
    font-size: 2.5rem;
    color: #FFFFFF;
    margin-bottom: 0.2em; }
    @media all and (min-width: 48em) {
      .c-hero--heading {
        font-size: 4.375rem; } }
  .c-hero--subheading {
    color: #FFFFFF;
    font-weight: 400;
    font-size: 1.5625rem; }
    @media all and (min-width: 48em) {
      .c-hero--subheading {
        font-size: 2.1875rem; } }
  .c-hero.nobg {
    height: auto;
    padding: 3em 0 0.5em; }
    .c-hero.nobg .c-hero--heading {
      color: #0D3657;
      font-size: 2.1875rem;
      border-bottom: 1px solid #E3E3E3;
      padding-bottom: 1em; }

.c-videobanner {
  position: relative; }
  @media all and (min-width: 48em) {
    .c-videobanner .c-video--embed:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 10;
      background: rgba(0, 0, 0, 0.15); } }
  .c-videobanner .c-video--embed {
    position: relative;
    height: 100vh;
    max-height: calc(80vh - 55px); }
    .c-videobanner .c-video--embed video {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      max-height: 100%;
      object-fit: cover; }
    .c-videobanner .c-video--embed + .c-videobanner--content {
      padding: 2rem 0; }
    @media screen and (min-width: 768px) {
      .c-videobanner .c-video--embed {
        max-height: calc(90vh - 75px); } }
  .c-videobanner--content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100; }
    @media all and (min-width: 48em) {
      .c-videobanner--content {
        bottom: initial; } }
  .c-videobanner--heading {
    font-size: 1.875rem;
    color: #FFFFFF;
    margin-bottom: 0.2em; }
    @media all and (min-width: 48em) {
      .c-videobanner--heading {
        font-size: 3.125rem; } }
    @media all and (min-width: 64em) {
      .c-videobanner--heading {
        font-size: 4.375rem; } }
  .c-videobanner--link {
    font-size: 1.25rem;
    font-weight: 400; }
    .c-videobanner--link a {
      color: #FFFFFF; }
    @media all and (min-width: 64em) {
      .c-videobanner--link {
        font-size: 2.8125rem; } }
    .c-videobanner--link i {
      margin-left: 1.2em;
      font-size: 1.8rem;
      transition: all 0.2s ease-in-out;
      -ms-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out; }
    .c-videobanner--link:hover i {
      margin-left: 1.5em; }
  .c-videobanner--arrow {
    display: inline-block;
    background: url(../images/banner-arrow.svg) no-repeat center left;
    background-size: cover;
    height: 30px;
    width: 80px; }

.c-breadcrumbs {
  padding: 1em 0; }
  .c-breadcrumbs ul {
    margin: 0; }
    .c-breadcrumbs ul li {
      display: inline-block;
      color: #0D3657;
      font-size: 0.8125rem;
      font-weight: 700; }
      .c-breadcrumbs ul li span {
        display: inline-block;
        padding: 0 0.75em;
        font-weight: 700; }
      .c-breadcrumbs ul li a {
        color: #0D3657;
        font-size: 0.8125rem;
        font-weight: 700; }

.c-introduction--heading {
  color: #0D3657;
  padding-bottom: 0.5em;
  margin-bottom: 1em; }

.c-introduction--text {
  color: #0D3657;
  font-size: 1.375rem; }

.c-introduction a {
  color: #0D3657;
  font-weight: 700; }

.c-introduction.home {
  text-align: center; }

.c-cards .c-btn, .c-cards .c-search--form button, .c-search--form .c-cards button {
  margin-top: 2em; }

.c-card {
  position: relative;
  margin-bottom: 1em;
  overflow: hidden;
  border-radius: 25px; }
  .c-card:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.1); }
  .c-card--img {
    width: 100%;
    transition: all 0.3s ease; }
  .c-card--heading {
    position: absolute;
    bottom: 1em;
    left: 1em;
    color: #FFFFFF;
    margin: 0;
    font-size: 1.4375rem;
    z-index: 20; }
    .c-card--heading i {
      font-size: 1.3rem;
      margin-left: 1.3em;
      transition: all 0.2s ease-in-out;
      -ms-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out; }
  .c-card:hover:after {
    background: rgba(13, 54, 87, 0.3); }
  .c-card:hover .c-card--img {
    transform: scale(1.05, 1.05); }
  .c-card:hover i {
    margin-left: 1.5em; }
  .c-card.closed {
    height: 0; }

.c-video--heading {
  color: #0D3657;
  padding-bottom: 0.5em;
  margin-bottom: 1em; }

.c-video--text {
  margin-bottom: 2em; }

.c-video-inline--heading {
  margin-top: 60px; }

.c-video-inline p {
  font-size: 1.375rem; }

.c-video-inline--container {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  border-radius: 25px;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; }
  .c-video-inline--container img.bg {
    width: 100% !important;
    max-width: 1000% !important;
    height: auto !important; }
  .c-video-inline--container .play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 8.5rem;
    color: #FFFFFF;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out; }
    .c-video-inline--container .play-btn:hover {
      font-size: 10rem;
      cursor: pointer; }
    @media all and (max-width: 48em) {
      .c-video-inline--container .play-btn {
        font-size: 4rem; }
        .c-video-inline--container .play-btn:hover {
          font-size: 5rem; } }

.c-video-popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.7);
  transition: all .5s ease; }
  .c-video-popup--hidden {
    opacity: 0;
    z-index: -1000;
    visibility: hidden;
    pointer-events: none; }
  .c-video-popup__video-wrapper {
    position: fixed;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 640px; }
  .c-video-popup__video {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    padding-top: 25px;
    height: 0; }
    .c-video-popup__video iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .c-video-popup__close-btn {
    position: absolute;
    right: 0;
    top: -15px;
    color: #FFFFFF; }
    .c-video-popup__close-btn:hover {
      text-decoration: none; }

.c-tiles a {
  border-radius: 25px; }

.c-tile {
  position: relative;
  max-height: 370px;
  overflow: hidden; }
  .c-tile:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 25px; }
  .c-tile--heading {
    position: absolute;
    bottom: 1em;
    left: 1em;
    color: #FFFFFF;
    margin: 0;
    font-size: 2.0625rem;
    z-index: 20; }
    .c-tile--heading i {
      font-size: 1.3rem;
      margin-left: 1.3em;
      transition: all 0.2s ease-in-out;
      -ms-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out; }
  .c-tile--image {
    overflow: hidden;
    height: 100%;
    border-radius: 25px; }
    .c-tile--image img {
      width: 100%;
      height: 100%;
      transition: all 0.3s ease; }
  .c-tile:hover:after {
    background: rgba(13, 54, 87, 0.3); }
  .c-tile:hover .c-tile--image img {
    transform: scale(1.05, 1.05); }
  .c-tile:hover i {
    margin-left: 1.5em; }

.c-news--heading {
  color: #0D3657;
  padding-bottom: 0.5em;
  margin-bottom: 1em; }

.c-news--header {
  position: relative; }

@media all and (min-width: 48em) {
  .c-news--filter {
    position: absolute;
    right: 0;
    top: 0; } }

.c-news--filter li {
  display: inline-block;
  padding: 0.5em 1em;
  cursor: pointer; }
  .c-news--filter li a {
    color: #0D3657; }
  .c-news--filter li.selected {
    background: #4F8FC7; }
    .c-news--filter li.selected a {
      color: #FFFFFF; }

.c-news--filter-select {
  position: absolute;
  right: 0;
  top: 0;
  background: #ffffff url(http://www.techandmath.gr/img/icons/small_down_arrow.gif) no-repeat 100% 50%;
  background-size: 5%;
  border-bottom: 1px solid #0D3657;
  float: left;
  padding: 10px 0;
  overflow: hidden;
  width: 260px; }
  .c-news--filter-select select {
    -webkit-appearance: none;
    border: none;
    box-shadow: none;
    background: transparent;
    background-image: none;
    font-size: 18px;
    font-weight: normal;
    color: #0D3657;
    padding-bottom: 12px\9;
    vertical-align: middle;
    width: 130%; }

.c-news--item--img {
  margin-bottom: 0.75em;
  overflow: hidden;
  position: relative;
  border-radius: 25px;
  padding-top: 69.83%; }
  .c-news--item--img:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
  .c-news--item--img img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: all 0.3s ease;
    object-fit: cover;
    border-radius: inherit; }
    .c-news--item--img img[src*='noImage'], .c-news--item--img img[src=''] {
      background: #efefef;
      font-size: 0; }

.c-news--item--heading {
  font-size: 1.5625rem;
  font-weight: 400;
  color: #0D3657; }

.c-news--item--date {
  color: #0D3657;
  text-transform: uppercase;
  text-decoration: none !important; }
  @media screen and (min-width: 768px) {
    .c-news--item--date {
      font-size: 1.125rem; } }

.c-news--item:hover .c-news--item--heading {
  text-decoration: underline; }

.c-news--item:hover .c-news--item--img:after {
  background: rgba(13, 54, 87, 0.3); }

.c-news--item:hover .c-news--item--img img {
  transform: scale(1.05, 1.05); }

.c-news p {
  font-size: 1.375rem; }

.c-news #news-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 1.5rem; }

.c-pagination {
  padding: 1em 0; }

.c-pages {
  margin: 0;
  text-align: center; }
  .c-pages--item {
    display: inline-block;
    padding: 0 0.5em;
    padding-right: 0.75em;
    position: relative; }
    .c-pages--item:after {
      content: "";
      width: 1px;
      height: 10px;
      background: #999999;
      position: absolute;
      right: 0;
      top: 35%; }
    .c-pages--item:last-child:after {
      content: none; }
    .c-pages--item a {
      color: #0D3657;
      font-weight: 700; }

.c-stayinformed {
  background: #F4F4F4; }
  .c-stayinformed--heading {
    color: #0D3657; }

.c-footer {
  background: #03121F;
  padding: 3em 0 2em; }
  .c-footer--nav li {
    margin-bottom: 0.75em; }
    .c-footer--nav li a {
      text-transform: uppercase;
      font-size: 0.8125rem; }
  .c-footer--contact--name {
    color: #4F8FC7;
    text-transform: uppercase;
    font-size: 0.8125rem;
    font-weight: 400; }
  .c-footer--contact--details {
    color: #37566f;
    font-size: 0.8125rem;
    line-height: 2.4em;
    font-weight: 700; }
    .c-footer--contact--details a {
      color: #37566f;
      text-decoration: underline; }
  .c-footer--legal {
    margin-top: 7em;
    color: #37566f;
    font-size: 0.8125rem; }
    .c-footer--legal--links li {
      display: inline-block;
      padding-left: 0.75em; }
      .c-footer--legal--links li a {
        color: #37566f; }
  .c-footer--form .umbraco-forms-fergusonFooter {
    display: none; }
  .c-footer--form label {
    color: #4F8FC7;
    font-size: 0.875rem;
    font-weight: 700;
    margin-bottom: 0.5em;
    display: inline-block; }
  .c-footer--form--field {
    background: #0D3657;
    width: 75%; }
    .c-footer--form--field input {
      background: transparent;
      border: none;
      width: calc(100% - 43px);
      padding: 10px 10px;
      display: inline-block;
      vertical-align: middle;
      color: #FFFFFF; }
      .c-footer--form--field input:focus {
        outline: none; }
      .c-footer--form--field input.input-validation-error {
        border: 1px solid red; }
    .c-footer--form--field button {
      background: rgba(79, 143, 199, 0.5);
      border: none;
      display: inline-block;
      width: 40px;
      height: 40px;
      vertical-align: middle;
      cursor: pointer; }
      .c-footer--form--field button .far {
        font-size: 1.25rem; }
      .c-footer--form--field button:hover {
        background: #4F8FC7; }
  .c-footer--form #signupValidiatorMsg {
    font-size: 0.8125rem;
    color: #FFFFFF; }
  .c-footer--social {
    margin-top: 1.5em; }
  .c-footer .b.footer-logo-grid {
    margin-bottom: 1rem;
    overflow: hidden; }
    .c-footer .b.footer-logo-grid + .b {
      clear: both; }
  .c-footer .list-footer-logos,
  .c-footer .list-footer-logos li,
  .c-footer .list-footer-logos img {
    display: block;
    float: left;
    position: relative;
    width: 100%;
    padding: 0;
    list-style: none; }
  .c-footer .list-footer-logos li,
  .c-footer .list-footer-logos img {
    margin: 0; }
  .c-footer .list-footer-logos li {
    width: auto;
    display: inline-block;
    float: none;
    vertical-align: middle;
    margin: 0 1em 1em 0; }
  .c-footer .list-footer-logos img {
    width: auto;
    height: auto;
    max-width: 280px;
    max-height: 100px; }
    @media screen and (max-width: 400px) {
      .c-footer .list-footer-logos img {
        max-width: 100%; } }

.c-form--blue label {
  display: none; }

.c-form--blue .umbraco-forms-field {
  margin-bottom: 1em; }

.c-form--blue input[type="text"],
.c-form--blue input[type="email"],
.c-form--blue textarea,
.c-form--blue select {
  display: block;
  background: #E3E7EA;
  border: none;
  padding: 10px;
  width: 100%;
  height: 40px;
  font-family: "Lato", sans-serif;
  font-size: 0.875rem; }

.c-form--blue input[type="submit"] {
  display: block;
  width: 100%;
  background: #0D3657;
  color: #FFFFFF;
  border: none;
  padding: 10px 5px; }

.c-textimage--heading {
  color: #0D3657;
  margin-bottom: 1.25em; }

.c-textimage--image {
  position: relative; }
  .c-textimage--image img {
    width: 100%;
    border-radius: 25px; }

.c-textimage--date strong {
  color: #0D3657; }

.c-textimage--media {
  position: relative; }

.c-textimage ul {
  margin-left: 1em; }
  .c-textimage ul li {
    list-style-type: disc;
    padding-left: 0.5em; }
    .c-textimage ul li a {
      color: #0D3657; }

.c-enquiry--heading {
  font-size: 1.625rem;
  padding: 1em 0; }
  .c-enquiry--heading.image {
    background: url(../images/pin-contact.png) no-repeat right center; }

.c-enquiry form textarea {
  min-height: 150px; }

.c-nextprevious--heading {
  font-size: 1.5rem; }

.c-nextprevious a {
  color: #0D3657;
  text-transform: uppercase;
  display: inline-block; }

.c-nextprevious--prev {
  background: url(../images/previous.svg) no-repeat left center;
  padding-left: 40px; }

.c-nextprevious--next {
  background: url(../images/next.svg) no-repeat right center;
  padding-right: 40px;
  text-align: right; }

.c-twocolumns--heading {
  color: #0D3657;
  margin-bottom: 1.25em; }

.c-twocolumns ul {
  margin-left: 1em; }
  .c-twocolumns ul li {
    list-style-type: disc;
    padding-left: 0.5em; }
    .c-twocolumns ul li a {
      color: #0D3657; }

.c-accordion {
  padding: 4em 0;
  background: #F4F4F4; }
  .c-accordion--item {
    background: #FFFFFF;
    border-bottom: 2px solid #F4F4F4;
    position: relative;
    cursor: pointer; }
    .c-accordion--item:after {
      content: ".";
      clear: both;
      display: block;
      height: 0;
      visibility: hidden; }
    .c-accordion--item--image {
      width: 35%;
      float: left;
      padding-bottom: 35%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat; }
    .c-accordion--item--text {
      padding: 1em;
      width: 65%;
      float: left; }
      @media all and (min-width: 64em) {
        .c-accordion--item--text {
          padding: 2em; } }
    .c-accordion--item--heading {
      font-size: 1.375rem; }
      @media all and (min-width: 48em) {
        .c-accordion--item--heading {
          font-size: 1.625rem; } }
    .c-accordion--item--arrow {
      position: absolute;
      bottom: 10px;
      left: 40%; }
      .c-accordion--item--arrow img {
        width: 40px; }
      @media all and (min-width: 48em) {
        .c-accordion--item--arrow {
          bottom: 20px; }
          .c-accordion--item--arrow img {
            width: 50px; } }
    .c-accordion--item--information {
      display: none; }
    .c-accordion--item.open .c-accordion--item--arrow {
      transform: rotate(180deg); }
    .c-accordion--item.unselected {
      opacity: 0.75; }
  .c-accordion--list {
    margin-left: 2em; }
    .c-accordion--list li {
      list-style-type: disc;
      color: #0D3657;
      padding-left: 1em; }
      .c-accordion--list li a {
        color: #0D3657; }
  .c-accordion--expand {
    background: white;
    padding: 1em;
    border-bottom: 2px solid #F4F4F4;
    display: none; }
    @media all and (min-width: 48em) {
      .c-accordion--expand {
        padding: 2em; } }

.c-ship--image {
  position: relative;
  margin-bottom: 1em; }
  .c-ship--image--magnifier {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0.5em 1em;
    background: #4F8FC7;
    color: #FFFFFF;
    font-size: 1.0625rem; }

.c-ship--status {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  color: #FFFFFF;
  font-size: 0.8125rem;
  text-transform: uppercase;
  padding: 0.5em 0; }
  .c-ship--status.fitting {
    background: #4F8FC7; }
  .c-ship--status.construction {
    background: #0D3657; }

.c-ship--name {
  font-size: 1.375rem;
  margin: 0; }

.c-ship--builtfor {
  color: #333333; }

.c-history {
  padding: 4em 0 2em; }
  .c-history--heading {
    color: #0D3657;
    margin-bottom: 1em; }

.c-timeline {
  padding: 1em 0 4em;
  margin: 2em 0; }
  @media all and (min-width: 34.25em) {
    .c-timeline {
      background: url(../images/timeline-bg.gif) repeat-y top center; } }

.c-year {
  margin-bottom: 2em;
  position: relative; }
  .c-year--image {
    margin-bottom: 1em; }
  @media all and (min-width: 34.25em) {
    .c-year:nth-of-type(odd) {
      padding-right: 2em; }
      .c-year:nth-of-type(odd):after {
        content: "";
        position: absolute;
        right: 0;
        width: 20px;
        top: 2.25em;
        height: 1px;
        background: #999999; }
    .c-year:nth-of-type(even) {
      margin-top: 4em;
      padding-left: 2em; }
      .c-year:nth-of-type(even):before {
        content: "";
        position: absolute;
        left: 0;
        width: 20px;
        top: 2.25em;
        height: 1px;
        background: #999999; } }
  .c-year--heading {
    font-size: 1.5rem;
    position: relative; }

.c-team--member {
  color: #333333;
  margin-bottom: 1.75em;
  cursor: pointer; }
  .c-team--member--image {
    position: relative;
    overflow: hidden;
    margin-bottom: 1.5em;
    min-height: 2px;
    background-color: #eaeaea; }
  .c-team--member--img {
    display: none;
    width: 100%;
    transition: all 0.3s ease; }
  .c-team--member--name {
    font-size: 0.9375rem;
    color: #333333;
    margin-bottom: 0.05em;
    font-weight: 700; }
  .c-team--member--job {
    text-transform: uppercase;
    font-size: 0.8125rem; }
  .c-team--member:hover .c-team--member--image:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(13, 54, 87, 0.3); }
  .c-team--member:hover .c-team--member--image img {
    transform: scale(1.05, 1.05); }

.c-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #FFFFFF;
  display: none;
  overflow-y: scroll;
  overflow-x: hidden; }
  .c-modal--content {
    position: relative;
    padding-top: 10%; }
  .c-modal--close {
    position: absolute;
    top: 0;
    right: -20px;
    padding: 1em;
    cursor: pointer; }
    .c-modal--close img {
      width: 30px; }
  .c-modal--name {
    color: #0D3657;
    margin-bottom: 0.2em;
    font-size: 1.5rem;
    font-weight: 400; }
  .c-modal--job {
    color: #333333;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
    margin-top: 0; }
  .c-modal.open {
    display: block; }
  .c-modal--nav {
    position: relative;
    margin-top: 1em; }
    @media all and (min-width: 34.25em) {
      .c-modal--nav {
        position: absolute;
        left: -30px;
        right: -30px;
        top: 150px; } }
    @media all and (min-width: 34.25em) {
      .c-modal--nav {
        left: -50px;
        right: -50px; } }
  .c-modal--linkedin {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #999999;
    text-align: center;
    padding-top: 10px; }
    .c-modal--linkedin i {
      color: #FFFFFF;
      font-size: 1.625rem; }

.js--modal {
  cursor: pointer; }
  .js--modal img {
    height: 50px;
    display: inline-block; }
    @media all and (min-width: 48em) {
      .js--modal img {
        height: 100px; } }

.c-vacancy ul {
  margin-left: 1em; }
  .c-vacancy ul li {
    list-style-type: disc;
    padding-left: 0.5em; }
    .c-vacancy ul li a {
      color: #0D3657; }

.c-vacancy--form {
  background: #F4F4F4;
  padding: 1em; }
  @media all and (min-width: 48em) {
    .c-vacancy--form {
      padding: 2em; } }
  @media all and (min-width: 64em) {
    .c-vacancy--form {
      padding: 3em; } }
  .c-vacancy--form label {
    color: #000000;
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 700; }
  .c-vacancy--form input[type="text"],
  .c-vacancy--form textarea {
    width: 100%;
    background: #FFFFFF;
    border: none;
    padding: 10px; }
  .c-vacancy--form textarea {
    min-height: 250px; }
  .c-vacancy--form .umbraco-forms-field {
    margin-bottom: 1.5em; }
  .c-vacancy--form .umbraco-forms-tooltip {
    display: block;
    font-weight: 700;
    font-size: 0.875rem; }
  .c-vacancy--form input[type="submit"] {
    display: inline-block;
    background: #0D3657;
    color: #FFFFFF;
    border: none;
    padding: 1em 2em;
    width: 100%; }
  .c-vacancy--form .uploadyourcv .umbraco-forms-field-wrapper {
    position: relative;
    margin-bottom: 0.75em; }
    .c-vacancy--form .uploadyourcv .umbraco-forms-field-wrapper .fileupload {
      cursor: pointer;
      margin-top: -20px; }
      .c-vacancy--form .uploadyourcv .umbraco-forms-field-wrapper .fileupload .uploadMsg {
        font-weight: 700;
        font-size: 0.875rem;
        margin-left: 0.5em; }
  .c-vacancy--form .uploadyourcv input[type="file"] {
    opacity: 0;
    height: 0; }

.c-backtop {
  padding: 2em 0; }
  .c-backtop--link {
    display: inline-block;
    background: #999999;
    padding: 0.55em 1.5em;
    border-radius: 5px; }
    .c-backtop--link .far {
      color: #FFFFFF;
      font-size: 1.875rem; }

.c-sitemap--menu li a {
  padding: 0.45em 0;
  display: inline-block;
  font-size: 1rem; }

.c-sitemap--toplevel {
  font-weight: 700; }

.c-sitemap--sublevel {
  border-left: 5px solid #E3E3E3;
  padding-left: 2em; }

/**
 *  7. UTILITIES
 */
/**
 * UTILITIES.LAYOUT
 *
 * @description : These classes trump other preceeding styles.
 *                Userful for quickly applying common adjustments.
 */
.u-fr {
  float: right; }

.u-fl {
  float: left; }

.u-mb {
  margin-bottom: 1.25rem; }

/**
 * UTILITIES.TYPE
 *
 * @description : These classes trump other preceeding styles.
 *                Userful for quickly applying common adjustments.
 */
.u-tc {
  text-align: center; }

.u-tr {
  text-align: right; }

.u-tl {
  text-align: left; }
