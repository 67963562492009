/**
 * SETTINGS.COLORS
 *
 * @description : Color variable definitions
 *
 */

/**
 * Main Colours
 */
$alpha         : #4F8FC7;  // Color name
$beta          : #999999;  // Color name
$gamma         : #0D3657;  // C0D3657olor name
$delta         : #F4F4F4;  // Color name
$kappa         : #E3E3E3;  // Color name
$tau           : #03121F;  // Color name
$psi            : #333333;  // Color name
$omega       : #37566f;  // Color name

$blue: $alpha;
$darkBlue: $gamma;
$veryDarkBlue: $tau;
$darkGrey: $beta;
$newBlue:$omega;
$mediumGrey:$kappa;
$lightGrey:$delta;

$textColor:$psi;

$white:#FFFFFF;
$black:#000000;

/**
 * UX/Validation colors
 */
$notice        : #2ab9ff;  // Blue
$confirm       : #6fd100;  // Green
$alert         : #ffa800;  // Orange
$warning       : #c60000;  // Red
