/**
 * UTILITIES.TYPE
 *
 * @description : These classes trump other preceeding styles.
 *                Userful for quickly applying common adjustments.
 */


.u-tc {
    text-align: center;
}

.u-tr {
    text-align: right;
}

.u-tl {
    text-align: left;
}
