.c-textimage{
    @extend .o-padding;

    &--heading{
        color:$darkBlue;
        margin-bottom:1.25em;
    }
    
    &--image{
        position:relative;

        img{
            width:100%;
            border-radius: 25px;
        }
    }

    &--date{
        strong{
            color:$darkBlue;
        }
    }

    &--media{
        position:relative;
    }

    ul{
        margin-left:1em;

        li{
            list-style-type: disc;
            padding-left:0.5em;
            
            a{
                color:$darkBlue;
            }
        }
    }
}