.c-btn {
    display:inline-block;
    background:$darkBlue;
    color:$white;
    border:none;
    padding:1em 2em;

    letter-spacing: 1px;
    border-radius: 5px;
    text-transform: uppercase;

    &--filled{
        background:$white;
        color:$blue;
        border:2px solid $mediumGrey;
        text-transform:uppercase;

        &:hover{
            background:$blue;
            color:$white;
            border-color:$blue;
            text-decoration:none;
        }
    }

    &.upload{
        padding:0.35em 2em;
        color:$black;
        font-weight: 700;
        font-size:rem(14);
        border:1px solid $darkGrey;
        @extend .o-gradient;
    }
}