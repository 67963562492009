.c-navigation{
    @media all and (max-width:$md){
        position: fixed;
        top:0;
        width:280px;
        right: -280px;
        background:$darkBlue;
        z-index: 2000;
        height: 100%;
        text-align:left;
        padding:2em;

        ul{
            li{
                padding:0.5em 0;
                a{
                    color:$white;
                    text-transform:uppercase;
                    font-size:rem(17);
                }
            }
        }
    }

    @media all and (min-width:$md){
        display:inline-block;

        ul{
            margin:0;
            padding:0;
            display: inline-block;

            li{
                display: inline-block;
                padding:0 0.5em;

                @media all and (min-width:$lg){
                    padding:0 0.75em;
                }
                
                a{
                    font-weight:700;
                    display: block;
                    padding:1.1em 0;
                    border-bottom:3px solid transparent;
                }

                &:hover{
                    a{
                        border-color:$mediumGrey;
                        text-decoration:none;
                    }
                }

                &.selected{
                    a{
                        border-color:$blue;
                    }
                }
            }
        }
    }
}