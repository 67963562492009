.c-timeline{
    
    padding:1em 0 4em;
    margin:2em 0;

    @media all and (min-width:$sm){
        background:url(../images/timeline-bg.gif) repeat-y top center;
    }
}

.c-year{
    margin-bottom:2em;
    position:relative;

    &--image{
        margin-bottom:1em;
    }

    @media all and (min-width:$sm){

        &:nth-of-type(odd){
            padding-right: 2em;

            &:after{
                content:"";
                position:absolute;
                right: 0;
                width:20px;
                top:2.25em;
                height: 1px;
                background:$darkGrey;
            }
        }

        &:nth-of-type(even){
            margin-top:4em;
            padding-left:2em;

            &:before{
                content:"";
                position:absolute;
                left: 0;
                width:20px;
                top:2.25em;
                height: 1px;
                background:$darkGrey;
            }
        }
    }

    &--heading{
        font-size:rem(24);
        position:relative;
    }
}