.c-sitemap{
    @extend .o-padding--small;

    &--menu{
        li{
            a{
                padding:0.45em 0;
                display:inline-block;
                font-size:rem(16);
            }
        }
    }

    &--toplevel{
        font-weight:700;
    }

    &--sublevel{
        border-left:5px solid $mediumGrey;
        padding-left:2em;
    }

}