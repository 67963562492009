/**
 * SETTINGS.BASE
 *
 * @description : Key application variables and settings
 *
 */

$base-font-size:    16px;
$base-line-height:  1.8;

// Spacing unit for calculating consistent margins/padding etc
$spacer: 1.25rem; // 20px;
