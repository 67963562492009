/**
 * SETTINGS.BREAKPOINTS
 *
 * @description : Define major breakpioints
 *
 */


// Instruct breakpoint-sass to convert all px breakpoints to ems
//$breakpoint-to-ems: true;


// Breakpoints
$breakpoints: (
    "sm" : 34.25em,    // 548px
    "md" : 48em,       // 768px
    "lg" : 64em,       // 1024px
    "xl" : 90em        // 1440px
);

// Dump our breakpoints into some vars for easy reference in breakpoint mixins
$sm:  map-get($breakpoints, sm);
$md:  map-get($breakpoints, md);
$lg:  map-get($breakpoints, lg);
$xl:  map-get($breakpoints, xl);

/**
    e.g.
    .thing {
        background: green;

        @include breakpoint($md){
            background: blue;
        }
    }
*/
