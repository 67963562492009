.c-header{
    position:relative;
    z-index:20;
    background:$white;
    min-height: 44px;

    .b{
        position:relative;
        
    }

    &--logo{
        width:75%;
    }
} 