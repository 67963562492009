.c-breadcrumbs{
    padding:1em 0;

    ul{
        margin:0;

        li{
            display: inline-block;
            color:$darkBlue;
            font-size:rem(13);
            font-weight: 700;

            span{
                display: inline-block;
                padding:0 0.75em;
                font-weight: 700;
            }

            a{
                color:$darkBlue;
                font-size:rem(13);
                font-weight: 700;
            }
        }
    }
}