.align-left { text-align: left;}
.align-center { text-align: center;}
.align-right { text-align: right;}

@each $breakpoint,$width in $breakpoints{
  @media (min-width:$width){
    .align-left-#{$breakpoint} { text-align: left;}
    .align-center-#{$breakpoint} { text-align: center;}
    .align-right-#{$breakpoint} { text-align: right;}
  }
}
