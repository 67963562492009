.c-videobanner{
    position:relative;
    
    @media all and (min-width:$md){
        .c-video--embed{
            &:before{
                content:"";
                position: absolute;
                top:0;
                bottom:0;
                left:0;
                right: 0;
                z-index: 10;
                background:rgba($black,0.15);
            }
        }
    }

    .c-video--embed {
        position: relative;
        height: 100vh;
        max-height: calc(80vh - 55px);

        video {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            max-height: 100%;
            object-fit: cover;

        }

        & + .c-videobanner--content {
            padding: 2rem 0;
        }

        @media screen and (min-width: 768px) {
            max-height: calc(90vh - 75px);
        }
    }

    &--content{
        position: absolute;
        left:0;
        right: 0;
        bottom:0;
        z-index: 100;

        @media all and (min-width:$md){
            bottom:initial;
        }
    }


    &--heading{
        font-size:rem(30);
        color:$white;
        margin-bottom:0.2em;

        @media all and (min-width:$md){
            font-size:rem(50);
        }

        @media all and (min-width:$lg){
            font-size:rem(70);
        }
    }

    &--link{
        font-size:rem(20);
        font-weight: 400;

        a{
            color:$white;
        }

        @media all and (min-width:$lg){
            font-size:rem(45);
        }

        i{
            margin-left: 1.2em;
            font-size: 1.8rem;
            @include transition-all(.2s);
        }

        &:hover{
            i{
                margin-left: 1.5em;
            }
        }
    }

    &--arrow{
        display:inline-block;
        background:url(../images/banner-arrow.svg) no-repeat center left;
        background-size:cover;
        height:30px;
        width:80px;
    }
}