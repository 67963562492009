.c-team{
    @extend .o-padding;

    &--member{
        color:$textColor;
        margin-bottom:1.75em;
        cursor: pointer;

        &--image{
            position:relative;
            overflow:hidden;
            margin-bottom:1.5em;
            min-height: 2px;
            background-color: #eaeaea;
        }

        &--img{
            display:none;
            width:100%;
            transition:all 0.3s ease;
        }

        &--name{
            font-size:rem(15);
            color:$textColor;
            margin-bottom:0.05em;
            font-weight: 700;
        }

        &--job{
            text-transform:uppercase;
            font-size:rem(13);
        }

        &:hover{
            .c-team--member--image{
                &:after{
                    content:"";
                    position:absolute;
                    top:0;
                    bottom:0;
                    left:0;
                    right: 0;
                    background:rgba($darkBlue,0.3);
                }

                img{
                    transform: scale(1.05, 1.05);
                }
            }
        }
    }
}

.c-modal{
    position:fixed;
    top:0;
    bottom:0;
    left:0;
    right: 0;
    background:$white;
    display: none;
    overflow-y: scroll;
    overflow-x: hidden;


    &--content{
        position:relative;
        padding-top:10%;
    }

    &--close{
        position:absolute;
        top:0;
        right:-20px;
        padding:1em;
        cursor: pointer;
        
        img{
            width:30px;
        }
    }

    &--name{
        color:$darkBlue;
        margin-bottom:0.2em;
        font-size:rem(24);
        font-weight: 400;
    }

    &--job{
        color:$textColor;
        text-transform:uppercase;
        font-size:rem(16);
        font-weight: 700;
        margin-top:0;
    }

    &.open{
        display:block;
    }

    &--nav{
        position: relative;
        margin-top:1em;

        @media all and (min-width:$sm){
            position: absolute;
            left:-30px;
            right: -30px;
            top:150px;
        }

        @media all and (min-width:$sm){
            left:-50px;
            right: -50px;
        }
    }

    &--linkedin{
        display:inline-block;
        width:50px;
        height:50px;
        border-radius: 50%;
        background:$darkGrey;
        text-align:center;
        padding-top:10px;
        
        i{
            color:$white;
            font-size:rem(26);
        }
    }
}

.js--modal{
    cursor:pointer;

    img{
        height:50px;
        display: inline-block;

        @media all and (min-width:$md){
            height: 100px;
        }
    }
}