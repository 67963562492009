.c-video{
    @extend .o-padding;

    &--heading{
        color:$darkBlue;
        //border-bottom:1px solid $mediumGrey;
        padding-bottom:0.5em;
        margin-bottom:1em;
    }

    &--text{
        margin-bottom:2em;
    }
}

//video inline
.c-video-inline{
	&--heading{
		margin-top: 60px;
	}
	p{
		font-size:rem(22);
	}
	&--container{
		position: relative;
		padding-bottom: 56.25%; /* 16:9 */
		border-radius: 25px;
		@include bgCover;
		.play-btn{
			@include middle;
			background-color: transparent;
			outline: none;
			border: none;
			font-size: 8.5rem;
			color: $white;
			@include transition-all(.3s);
			&:hover{
				font-size: 10rem;
				cursor: pointer;
			}
			@media all and (max-width:$md){
				font-size: 4rem;
				&:hover{
					font-size: 5rem;
				}
			}
		}
	}
}
.c-video-popup {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
	background: rgba(0,0,0, .7);
	transition: all .5s ease;
	
	&--hidden {
	  opacity: 0;
	  z-index: -1000;
	  visibility: hidden;
	  pointer-events: none;
	}
	
	&__video-wrapper {
	  position: fixed;
	  width: 100%;
	  left: 50%;
	  top: 50%;
	  transform: translate(-50%, -50%);
	  width: 100%;
	  max-width: 640px;
	}
	&__video {
	  position: relative;
		padding-bottom: 56.25%; /* 16:9 */
		padding-top: 25px;
		height: 0;
	  
	  iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	  }
	}
	
	&__close-btn {
	  position: absolute;
	  right: 0;
	  top: -15px;
	  color: $white;
	  &:hover{
		text-decoration: none;
	  }
	}
}